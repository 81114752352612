
function ErrorNotFound_ctrl(forCtrler) {

	const handleClickOnWelcome = () => {
		forCtrler.navigate("/welcome");
	};

	return {
		handleClickOnWelcome: handleClickOnWelcome,
	};
} 

export default ErrorNotFound_ctrl;