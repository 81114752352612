
import { useNavigate } from 'react-router-dom';

import { Logo_ctrl } from '../../../controler/';

import {
	StyLogoPlaceDiv,
	StyLogoImgImg,
} from '../../../style/';

import imgLogo from '../../../img/tools/logo.jpeg'

function Logo() {

	const navigate = useNavigate();

	const forCtrler = {
		navigate: navigate,
	};
	const ctrler = Logo_ctrl(forCtrler);

	return (
		<StyLogoPlaceDiv>
			<StyLogoImgImg src={imgLogo} alt='logo' onClick={ctrler.hamdleClickOnLogo}/>
		</StyLogoPlaceDiv>
	);
} 

export default Logo;