
import { useSelector } from 'react-redux';
import { useState } from 'react';

import { Getter } from '../../../../generaltools';

import {
	globalStore,
	getAdminCurrItem,
	getFAdmIte_ti,
	getFAdmIte_ds,
	getFAdmIte_ta,
	getFAdmIte_co,
	getFAdmIte_or,
	getFAdmIte_ip,
	getFAdmIte_ci,
	getFAdmIte_av,
	getFAdmIte_en,
	getFAdmIte_de,
	getAllItem,
	getAdminCurrCategory,
	setFAdmIte_or,
	setFAdmIte_co,
} from '../../../../globalstate';

import { AdminAddManageItem_ctrl } from '../../../../controler/';

import {
	AdminAddItem,
	AdminManageItem,
} from './';

function AdminAddManageItem({addManage, sectionList, categoryList}) {

	const item_old = useSelector(getAdminCurrItem(globalStore.getState())) || {};

	const item_ti = useSelector(getFAdmIte_ti(globalStore.getState())) || "";
	const item_ds = useSelector(getFAdmIte_ds(globalStore.getState())) || "";
	const item_ta = useSelector(getFAdmIte_ta(globalStore.getState())) || "";
	const item_co = useSelector(getFAdmIte_co(globalStore.getState())) || 0;
	const item_or = useSelector(getFAdmIte_or(globalStore.getState())) || 0;
	const item_ip = useSelector(getFAdmIte_ip(globalStore.getState())) || "";
	const item_ci = useSelector(getFAdmIte_ci(globalStore.getState())) || 0;
	const item_av = useSelector(getFAdmIte_av(globalStore.getState()));
	const item_en = useSelector(getFAdmIte_en(globalStore.getState()));
	const item_de = useSelector(getFAdmIte_de(globalStore.getState()));

	const currCate = useSelector(getAdminCurrCategory(globalStore.getState())) || {};
	const item_all = useSelector(getAllItem(globalStore.getState())) || [];
	const maxOrdreP1 = Getter.getMaxItemOrderP1(item_all, currCate, item_ci);
	const maxCarouOrdreP1 = Getter.getMaxItemCarouOrderP1(item_all);

	if (item_or === 0) {
		globalStore.dispatch(setFAdmIte_or(maxOrdreP1));
	}

	if (item_co === 0) {
		globalStore.dispatch(setFAdmIte_co(maxCarouOrdreP1));
	}

	const [errorEpty_all, setErrorEpty_all] = useState(false);
	const [errorEpty_ti, setErrorEpty_ti] = useState(false);
	const [errorEpty_or, setErrorEpty_or] = useState(false);
	const [errorEpty_ip, setErrorEpty_ip] = useState(false);
	const [errorEpty_ci, setErrorEpty_ci] = useState(false);

	const forCtrler = {
		item_old: item_old,
		item_ti: item_ti,
		item_ds: item_ds,
		item_ta: item_ta,
		item_co: item_co,
		item_or: item_or,
		item_ip: item_ip,
		item_ci: item_ci,
		item_av: item_av,
		item_en: item_en,
		item_de: item_de,
		errorEpty_all: errorEpty_all,
		errorEpty_ti: errorEpty_ti,
		errorEpty_or: errorEpty_or,
		errorEpty_ip: errorEpty_ip,
		errorEpty_ci: errorEpty_ci,
		setErrorEpty_all: setErrorEpty_all,
		setErrorEpty_ti: setErrorEpty_ti,
		setErrorEpty_or: setErrorEpty_or,
		setErrorEpty_ip: setErrorEpty_ip,
		setErrorEpty_ci: setErrorEpty_ci,
		maxOrdreP1: maxOrdreP1,
		maxCarouOrdreP1: maxCarouOrdreP1,
		categoryList: categoryList,
		currCate: currCate,
	};
	const ctrler = AdminAddManageItem_ctrl(forCtrler);

	return (
		<div>
			{ addManage === 'A' ? (
					<AdminAddItem
						onCLickSave={ctrler.handleSaveItemAdd}
						ctrler={ctrler}
						sectionList={sectionList}
						categoryList={categoryList} />
				) : (
					<AdminManageItem
						onCLickSave={ctrler.handleSaveItemMod}
						ctrler={ctrler}
						sectionList={sectionList}
						categoryList={categoryList} />
				)
			}
		</div>
	);
}

export default AdminAddManageItem;