
import {
	StyGeneralButtonAddButton,
	StyAdminTitlePartH1,
} from '../../../../style';


function AdminActu({onClickActu}) {

	return (
		<div>
			<StyAdminTitlePartH1>Actu</StyAdminTitlePartH1>
			<StyGeneralButtonAddButton onClick={onClickActu}>Modifier</StyGeneralButtonAddButton>
		</div>
	);
} 

export default AdminActu;