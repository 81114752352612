
import { StyHeaderPlaceDiv } from '../../style';

import {
	Logo,
	MenuHeader,
} from './';

function Header() {
	return (
		<StyHeaderPlaceDiv>
			<Logo />
			<MenuHeader />
		</StyHeaderPlaceDiv>
	);
} 

export default Header;