
import styled from 'styled-components';


const StyAdminPlaceDiv = styled.div`
	margin-left: 5px;
`

const StyAdminTopDiv = styled.div`
	padding-top: 0px;
	margin-bottom: 20px;
`

const StyAdminFormDiv = styled.div`
	border-top: 3px solid rgb(0, 158, 199);
	border-radius: 10px 10px 0px 0px;

	margin-top: 20px;
	margin-bottom: 20px;
	padding-top: 10px;
	padding-left: 10px;
	padding-right: 10px;
`

const StyAdminTitlePartH1 = styled.h1`
	display: inline-block;
	text-decoration: underline;
`

const StyAdminTitlePartHoverH1 = styled.h1`
	display: inline-block;
	text-decoration: underline;

	&:hover {
		cursor: pointer;
	}
`

const StyAdminPartTittleH4 = styled.h4`
	text-align: center;

	font-weight: 900;
	font-size: 20px;
`

const StyAdminPartInputDiv = styled.div`
	margin-bottom: 5px;
	text-align: center;
`

const StyAdminPartInputLabel = styled.label`
	display: inline-block;
	width: 150px;
	text-align: right;
	margin-right: 3px;
`

const StyAdminPartInputInput = styled.input`
	width: 200px;
`

const StyAdminPartInputImg = styled.img`
	width: 100px;
	height: 100px;
	border: 1px solid rgb(215, 216, 221);
`

const StyAdminSaveInputButton = styled.button`
	border: 1px solid rgb(200, 200, 200);
	border-radius: 10px 10px 10px 10px;

	background-color: rgb(200, 200, 200);
	margin-top: 20px;	text-align: center;

	font-size: 15px;
	font-weight: 900;
	color: rgb(18, 21, 25);

	&:hover {
		cursor: pointer;
		background-color: rgb(0, 158, 199);
	}

	&:active {
		border: 1px solid rgb(0, 158, 199);
	}
`

const StyAdminPartInputTextarea = styled.textarea`
	min-width: 200px;
	max-width: 400px;
	width: 30%;
	height: 200px;
`

const StyAdminPartInputTextareaLabel = styled.label`
	display: inline-block;
	width: 150px;
	text-align: right;
	margin-right: 3px;
`

const StyAdminPartInputCheckboxInput = styled.input`
	width: 200px;
`

const StyAdminPartInputRadioLabel = styled.label`
	display: inline-block;
	width: 29px;
	text-align: right;
	margin-right: 3px;
	margin-left: 50px;
`

const StyAdminPartInputRadioInput = styled.input`
`

const StyAdminPartInputListSelect = styled.select`
	width: 210px;
`

	// border: 1px solid yellow;
const StyAdminPartImgItemPlaceDiv = styled.div`
	margin-top: -100px;
	margin-left: 105px;
	width: 350px;
`

	// border: 1px solid blue;
	// display: inline-block;
	// display: inline-block;
	// border: 1px solid blue;
	// width: 90vw;
const StyAdminPartImgCateDiv = styled.div`
	
`
	// display: inline-block;
	// border: 1px solid yellow;
	// width: 100%;
const StyAdminPartImgItemDiv = styled.div`
	display: inline-block;
`

// border: 1px solid red;
const StyAdminPartItemImgItemDiv = styled.div`
	
`

	// border: 1px solid red;
const StyAdminPartImgCateImg = styled.img`
	width: 100px;
	height: 100px;
`

	// border: 1px solid green;
const StyAdminPartImgItemImg = styled.img`
	width: 50px;
	height: 50px;
`

const StyAdminPartErrorDiv = styled.div`
	color: red;
	font-size: 14px;
	font-style: italic;
	font-weight: 400;
	text-align: center;
`

const StyAdminPartErrorSpan = styled.span`
	color: red;
	font-size: 14px;
	font-style: italic;
	font-weight: 400;
	margin-right: 7px;
`

export {
	StyAdminPlaceDiv,
	StyAdminTopDiv,
	StyAdminFormDiv,
	StyAdminTitlePartH1,
	StyAdminTitlePartHoverH1,
	StyAdminPartTittleH4,
	StyAdminPartInputDiv,
	StyAdminPartInputLabel,
	StyAdminPartInputInput,
	StyAdminPartInputImg,
	StyAdminSaveInputButton,
	StyAdminPartInputTextarea,
	StyAdminPartInputTextareaLabel,
	StyAdminPartInputCheckboxInput,
	StyAdminPartInputRadioLabel,
	StyAdminPartInputRadioInput,
	StyAdminPartInputListSelect,
	StyAdminPartImgItemPlaceDiv,
	StyAdminPartImgCateDiv,
	StyAdminPartImgItemDiv,
	StyAdminPartItemImgItemDiv,
	StyAdminPartImgCateImg,
	StyAdminPartImgItemImg,
	StyAdminPartErrorDiv,
	StyAdminPartErrorSpan,
};