
//const defaultImg = '../img/tools/default_img.jpeg';
//const imgLogo = '../img/tools/logo.jpeg';
const defaultImg = "/static/media/logo.58e3f1846b695bedbb15.jpeg";
const imgLogo = "/static/media/logo.58e3f1846b695bedbb15.jpeg";
//const BASE_DOM = window.location.href.split('/')[0];
const BASE_DOM = "https://www.bluevirus666.com";

module.exports = class SetHeader {

	static getHeaderPageWelcome = (info) => {
		let newTitle = "";
		if (info !== undefined && info.shop_address_city !== undefined && info.shop_address_city.substr(0, 1) !== "-") {
			newTitle = "Envie d'un tattoo dark à " + info.shop_address_city  + " ? Je vous propose mon art";
		} else {
			newTitle = "Envie d'un tattoo dark ? Je vous propose mon art";
		}
		let headerPage = {
			title: newTitle,
			descr: "Des tatouages pour tout le monde ! Je vous partage mes créations et ma passion pour mon métier de tatoueuse.",
			img: BASE_DOM.concat(imgLogo),
		};
		return headerPage;
	}

	static getHeaderPageContact = (info) => {
		let descr = '';
		if (info !== undefined && info.shop_name !== undefined && info.shop_address_city.substr(0, 1) !== "-") {
			descr = info.shop_name + ' : le shop où vous pouvez me trouver. Vous pouvez aussi me contacter sur mes réseaux.';
		} else {
			descr = "Je n'ai pas de shop pour le moment. Vous pouvez tout de même me contacter sur mes réseaux";
		}
		let headerPage = {
			title: 'Contactez moi (Blue Virus) sur mes réseaux sociaux ou par mail',
			descr: descr,
			img: BASE_DOM.concat(imgLogo),
		};
		return headerPage;
	}

	static getHeaderPageAbout = (aboutPara) => {

		let headerPage = {
			title: "Toutes mes publications ! N'hésitez pas à me contacter",
			descr: "Il n'y a pas d'article pour le moment. Ils arrivent très bientôt, n'hésitez pas à me contacter.",
			img: BASE_DOM.concat(defaultImg),
		};

		if (aboutPara.title !== undefined) {
			headerPage = {
				title: aboutPara.title,
				descr: aboutPara.content,
				img: BASE_DOM.concat(aboutPara.img_path) || BASE_DOM.concat(defaultImg),
			};
		}

		// let descrAbout = '';
		// let ind = 0;
		// aboutParas.forEach((aboutPara) => {
		// 	if (aboutPara.title_force === 1) {
		// 		ind++;
		// 		if (ind === 1) {
		// 			descrAbout = aboutPara.title;
		// 		} else {
		// 			descrAbout = descrAbout + ' | ' + aboutPara.title;
		// 		}
		// 	}
		// });

		// let headerPage = {
		// 	title: 'En savoir plus à mon propos ? Ou sur mon métier de tatoueuse ?',
		// 	descr: descrAbout,
		// 	img: BASE_DOM.concat(defaultImg),
		// };
		
		return headerPage;
	}

	static getHeaderPageCategory = (sections, sectionId, categories, categoryId, items) => {
		let cCurrSection = {};
		let cCurrCategory = {};
		let cItemsCate = [];
		let descrCate = '';

		sections.forEach((section) => {
			if (section.id.toString() === sectionId.toString()) {
				cCurrSection = section;
			}
		});

		categories.forEach((category) => {
			if (category.id.toString() === categoryId.toString()) {
				cCurrCategory = category;
			}
		});

		items.forEach((item) => {
			if (item.category_id.toString() === categoryId.toString()) {
				cItemsCate.push(item);
			}
		});

		switch(cItemsCate.length) {
			case 0:
				descrCate = "Ne ratez pas mes nouveaux postes de tatouages ! N'hésitez pas à me contacter.";
			break;
			
			case 1:
				descrCate = "Aimez-vous mon " + cItemsCate[0].title + " ? D'autres viendront bientôt !"
			break;
			
			case 2:
				descrCate = "Le " + cItemsCate[0].title + " et le " + cItemsCate[1].title + " sauront-ils vous séduire ?"
			break;
			
			case 3:
				descrCate = "Du " + cItemsCate[0].title + " au " + cItemsCate[2].title + " en passant par " + cItemsCate[1].title + " : voici mon art..."
			break;
			
			default:
				const itemLast = cItemsCate.length - 1;
				const itemMiddle = cItemsCate.length / 2;
				descrCate = "Du " + cItemsCate[0].title + " au " + cItemsCate[itemLast].title + " en passant par " + cItemsCate[parseInt(itemMiddle)].title + " : voici mon art..."
			break;
		}

		let headerPage = {
			title: cCurrSection.name + ' : ' + cCurrCategory.name,
			descr: descrCate,
			img: BASE_DOM.concat(cCurrCategory.img_path) || BASE_DOM.concat(defaultImg),
		};
		return headerPage;
	}

	static getHeaderPageItem = (categories, categoryId, items, itemId) => {
		let iCurrCategory = {};
		let iCurrItem = {};

		categories.forEach((category) => {
			if (category.id.toString() === categoryId.toString()) {
				iCurrCategory = category;
			}
		});

		items.forEach((item) => {
			if (item.id.toString() === itemId.toString()) {
				iCurrItem = item;
			}
		});
		let available = "DÉJÀ ENCRÉ";
		if (iCurrItem.available === true) {
			available = "Disponible";
		}

		let headerPage = {
			title: iCurrCategory.name + " : " + iCurrItem.title,
			descr: available + " : " + iCurrItem.description,
			img: BASE_DOM.concat(iCurrItem.path) || BASE_DOM.concat(defaultImg),
		};
		return headerPage;
	}


	static getHeaderPageActu = (actuPara) => {
		let headerPage = {};
		if (actuPara !== {} && actuPara.title) {
			let city = "";
			if (actuPara !== undefined && actuPara.loc_city !== undefined && actuPara.loc_city.substr(0, 1) !== "-") {
				city = actuPara.loc_city;
			}
			headerPage = {
				title: "Actu " + city + " : " + actuPara.title,
				descr: "Du " + actuPara.dt_start + " au " + actuPara.dt_end + " : Rendez vous au " + actuPara.loc_addr + ", " + actuPara.loc_cp + " " + actuPara.loc_city + ". " + actuPara.descr,
				img: BASE_DOM.concat(actuPara.path) || BASE_DOM.concat(defaultImg),
			};
		} else {
			headerPage = {
				title: "Pas d'actualité me concernant pour l'instant",
				descr: "Nouvel événement ? N'hésitez pas à consulter régulièrement mon site et mes réseaux sociaux, j'y mets toutes mes news.",
				img: BASE_DOM.concat(defaultImg),
			};
		}
		return headerPage;
	}

	static getHeaderPagePlan = () => {
		let headerPage = {
			title: "Blue Virus - Plan du site",
                        descr: "Des tatouages dark pour tout le monde ! Je vous partage mes créations et ma passion pour mon métier de tatoueuse.",
                        img: BASE_DOM.concat(imgLogo),
		};
		return headerPage;
	}

}
