
// import { CCarousel, CCarouselItem, CImage } from '@coreui/react';

// var Carousel = require('react-responsive-carousel').Carousel;
import { Carousel as CarouselResp } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { useNavigate } from 'react-router-dom';

import { Getter } from '../../../generaltools';

import { Carousel_ctrl } from '../../../controler/';

import {
	StyCarouselPlaceDiv,
	StyCarouselItemDiv,
	StyCarouselImgImg,
	StyCarouselLegendP,
} from '../../../style/';

function Carousel({carouselItems}) {

	const navigate = useNavigate();

	const forCtrler = {
		navigate: navigate,
		carouselItems: carouselItems,
	};
	const ctrler = Carousel_ctrl(forCtrler);

	const carouselItemsStrict = ctrler.getCarouselItemsOrdered();

	return (
		<div>
			<StyCarouselPlaceDiv>
				<CarouselResp showArrows={true} onClickItem={ctrler.handleCarouselRespOnClickItem} autoPlay={true} interval={2000} infiniteLoop={true} showThumbs={false}>
					{
						carouselItemsStrict.length !== 0 ? (
							carouselItemsStrict.map((carouselItem, liId) => {
								liId++;
								return (
									<StyCarouselItemDiv key={liId}>
					                    <StyCarouselImgImg src={Getter.getImgPath(carouselItem.path)} alt={carouselItem.title} />
					                    <StyCarouselLegendP className="legend">{carouselItem.title}</StyCarouselLegendP>
					                </StyCarouselItemDiv>
								)
							})
						) : (<StyCarouselItemDiv></StyCarouselItemDiv>)
					}
				</CarouselResp>
			</StyCarouselPlaceDiv>
		</div>
	);
} 

export default Carousel;