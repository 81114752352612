
import { useNavigate } from 'react-router-dom';

import { ErrorNotFound_ctrl } from '../../../controler/';

import { Getter } from '../../../generaltools';

import { HelmetComp } from '../../compoment/';

function ErrorNotFound() {

	const navigate = useNavigate();

	const forCtrler = {
		navigate: navigate,
	};
	const ctrler = ErrorNotFound_ctrl(forCtrler);

	const headerPage = Getter.getHeaderPage({pageName: 'NOTFOUND'});

	return (
		<div>
			<HelmetComp
				title = {headerPage.title}
				description = {headerPage.descr}
				image = {headerPage.img} />
			Sorry this page doesn´t exist. Click <span onClick={ctrler.handleClickOnWelcome}>here to come back into Blue Virus website</span> !
		</div>
	);
} 

export default ErrorNotFound;