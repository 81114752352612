
// import { useNavigate } from 'react-router-dom';
import { ItemItem } from '../../';

import { Getter } from '../../../../generaltools';

import {
	StyCatagoryItemLittlePlaceDiv,
	StyCatagoryItemLittleContentDiv,
	StyCatagoryItemLittleContentImg,
	StyCatagoryItemLittleContentSpan,
	StyCatagoryItemLittleItemPlaceDiv,
	StyCategoryItemLittleAdmItemListDiv,
} from '../../../../style/';

function CategoryItemLittle({location, category_item, itemList, onClick, setCategoryName, isCurrent}) {

	return (
		<StyCatagoryItemLittlePlaceDiv>
			<StyCatagoryItemLittleContentDiv onClick={onClick}>
				<StyCatagoryItemLittleContentImg src={Getter.getImgPath(category_item.img_path)} alt={category_item.name} iscurrent={isCurrent.toString()}/>
				<StyCatagoryItemLittleContentSpan>{setCategoryName(category_item.name, location)}</StyCatagoryItemLittleContentSpan>
			</StyCatagoryItemLittleContentDiv>
			<StyCategoryItemLittleAdmItemListDiv>
			{
				itemList.length !== 0 ? (
					itemList.map((itemItemListItem, liId) => {
						liId++;
						if (itemItemListItem.category_id === category_item.id && itemItemListItem.enable === true) {
							return (
								<StyCatagoryItemLittleItemPlaceDiv key={liId}>
									<ItemItem
										location={location}
										item={itemItemListItem} />
								</StyCatagoryItemLittleItemPlaceDiv>
							)
						} else { return (<div key={liId}></div>) }
					})
				) : (<div></div>)
			}
			</StyCategoryItemLittleAdmItemListDiv>
		</StyCatagoryItemLittlePlaceDiv>
	);
}

export default CategoryItemLittle;