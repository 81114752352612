
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
	globalStore,
	getActuPara,
	getAboutParagraphs,
} from '../../../globalstate';

import { Getter } from '../../../generaltools';

import { MenuHeader_ctrl } from '../../../controler/';

import {
	StyMenuHeaderPlaceDiv,
	StyMenuHeaderItemSpan,
	StyMenuHeaderItemImpSpan,
} from '../../../style/';

function MenuHeader() {

	const actuPara = useSelector(getActuPara(globalStore.getState())) || {};

	const about_texts = useSelector(getAboutParagraphs(globalStore.getState())) || [];
	let aboutLast = {};
	let lastNoAbout = 0;
	about_texts.forEach((about) => {
		//let lastNoAbout = 0;
//console.log("loop => about", about);
//console.log("loop => lastNoAbout", lastNoAbout);
		if (parseInt(about.no_article) >= parseInt(lastNoAbout) && parseInt(about.title_force) === 1) {
			lastNoAbout = about.no_article;
			aboutLast = about;
		}
	});

//console.log("after loop => aboutLast", aboutLast);

	const navigate = useNavigate();

	const forCtrler = {
		navigate: navigate,
		aboutLast: aboutLast,
	};
	const ctrler = MenuHeader_ctrl(forCtrler);

	return (
		<StyMenuHeaderPlaceDiv>
			<div>
				{
					Getter.menuDisplayActu(actuPara) === true ? (
						<StyMenuHeaderItemImpSpan onClick={ctrler.hamdleClickOnActu}>Actu</StyMenuHeaderItemImpSpan>
					) : (<span></span>)
				}
				<StyMenuHeaderItemSpan onClick={ctrler.hamdleClickOnWelcome}>Accueil</StyMenuHeaderItemSpan>
			</div>
			<StyMenuHeaderItemSpan onClick={ctrler.hamdleClickOnAbout}>À propos</StyMenuHeaderItemSpan>
			<StyMenuHeaderItemSpan onClick={ctrler.hamdleClickOnContact}>Contact</StyMenuHeaderItemSpan>
		</StyMenuHeaderPlaceDiv>
	);
} 

export default MenuHeader;
