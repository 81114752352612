
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
	globalStore,
	getCurrentItem,
	getAllSection,
	getAllCategory,
} from '../../../globalstate';

import { ItemItem_ctrl } from '../../../controler/';

import {
	ItemItemLittle,
	ItemItemBig,
	ItemItemAdmin,
} from './';

import {
	StyItemItemPlaceDiv,
	StyItemItemDiv,
} from '../../../style/';

function ItemItem({location, item, onClickItem}) {

	const navigate = useNavigate();
	const {id} = useParams();
	const currentItem = useSelector(getCurrentItem(globalStore.getState()));
	const allSection = useSelector(getAllSection(globalStore.getState()));
	const allCategories = useSelector(getAllCategory(globalStore.getState()));

	const forCtrler = {
		item: item,
		navigate: navigate,
		currentItem: currentItem,
		allSection: allSection,
		allCategories: allCategories,
		idItem: id,
	};
	const ctrler = ItemItem_ctrl(forCtrler);

	const isCurrentItem = ctrler.isCurrentItem();

	return (
		<StyItemItemPlaceDiv>
			{ location === 'MAIN' ? (
					<ItemItemBig
						location={location}
						item={item}
						onClick={ctrler.handleClickOnItem} />
				) : (
					<StyItemItemDiv>
						{ location === 'MENULEFT' ? (
								<ItemItemLittle
									location={location}
									item={item}
									onClick={ctrler.handleClickOnItem}
									isCurrent={isCurrentItem} />
							) : (
								<ItemItemAdmin
									location={location}
									item={item}
									onClickItem={ctrler.handleClickOnAdminItem} />
							)
						}
					</StyItemItemDiv>
				)
			}
		</StyItemItemPlaceDiv>
	);
} 

export default ItemItem;