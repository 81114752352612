
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

function HelmetComp({ title = '', description = '', image = '', noIndex = false }) {
	useEffect(() => {
		// dynamic refresh
	}, [title, description, image, noIndex]);

	return (
		<Helmet>
            { /* Standard metadata tags */ }
            <title>{title}</title>
            <link rel='canonical' href={ window.location.href } />
            <meta name='description' content={description} />
            { /* Open Graph tags (OG) */ }
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            {/* OG image tags */}
            <meta property="og:image" content={image} />
            <meta property="og:image:secure_url" content={image} />
            <meta property="og:image:type" content="image/jpeg" />
            <meta property="og:image:width" content="200" />
            <meta property="og:image:alt" content={title} />
            { /* Twitter tags */ }
            <meta name="twitter:creator" content="BlueVirus" />
            <meta name="twitter:card" content="”summary_large_image”" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
        </Helmet>
	);
} 

export default HelmetComp;


            // { noIndex === true ? (<meta name="robots" content="noindex" />) : ''}
