
import { useSelector } from 'react-redux';

import {
	globalStore,
	getFAdmSec_na,
	getFAdmSec_or,
	getFAdmSec_en,
	getFAdmSec_de,
} from '../../../../../globalstate';

import {
	StyAdminPartTittleH4,
	StyAdminPartInputDiv,
	StyAdminPartInputLabel,
	StyAdminPartInputInput,
	StyAdminSaveInputButton,
	StyAdminPartInputCheckboxInput,
	StyAdminPartInputRadioLabel,
	StyAdminPartInputRadioInput,
	StyAdminPartErrorDiv,
	StyAdminPartErrorSpan,
	StyAdminPartInputListSelect,
} from '../../../../../style/';

function AdminManageSection({onCLickSave, ctrler, sectionCurr}) {

	// let section_curr = {};
	// if (ctrler.section.name
	// 	|| ctrler.section.order
	// 	|| ctrler.section.enable
	// 	|| ctrler.section.is_delete) {
	// 	section_curr = ctrler.section;
	// } else {
	// 	section_curr = sectionCurr;
	// }
	const section_na = useSelector(getFAdmSec_na(globalStore.getState())) || "";
	const section_or = useSelector(getFAdmSec_or(globalStore.getState())) || 0;
	const section_en = useSelector(getFAdmSec_en(globalStore.getState()));
	const section_de = useSelector(getFAdmSec_de(globalStore.getState()));

	const sectionOrders = ctrler.putSectionOrder();

	return (
		<div>
			<StyAdminPartTittleH4>Modifier la section</StyAdminPartTittleH4>
			<form>
				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='name_section'>Nom :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{ctrler.errorEpty_na ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputInput type='text' id='name_section' name='name_section' value={section_na} onChange={ctrler.handleChangeSection_na} />
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='order_section'>Ordre :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{ctrler.errorEpty_or ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputListSelect id='order_section' name='order_section' value={section_or} onChange={ctrler.handleChangeSection_or}>
						{
							sectionOrders.map((sectionOrder, lkId) => {
								lkId++;
								return (<option value={sectionOrder} key={lkId}>{sectionOrder}</option>)
							})
						}
					</StyAdminPartInputListSelect>
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel>Activer :</StyAdminPartInputLabel>
					<StyAdminPartInputRadioLabel htmlFor='enable_y_section'>Oui</StyAdminPartInputRadioLabel>
						{ section_en === true ? (
								<StyAdminPartInputRadioInput type='radio' id='enable_y_section' name='enable_section' value='Y' defaultChecked onClick={ctrler.handleChangeSection_en} />
							) : (
								<StyAdminPartInputRadioInput type='radio' id='enable_y_section' name='enable_section' value='Y' onClick={ctrler.handleChangeSection_en} />
							)
						}
					<StyAdminPartInputRadioLabel htmlFor='enable_n_section'>Non</StyAdminPartInputRadioLabel>
						{ section_en === true ? (
								<StyAdminPartInputRadioInput type='radio' id='enable_n_section' name='enable_section' value='N' onClick={ctrler.handleChangeSection_en} />
							) : (
								<StyAdminPartInputRadioInput type='radio' id='enable_n_section' name='enable_section' value='N' defaultChecked onClick={ctrler.handleChangeSection_en} />
							)
						}
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='delete_section'>Supprimer :</StyAdminPartInputLabel>
					<StyAdminPartInputCheckboxInput type='checkbox' id='delete_section' name='delete_section' value={section_de} onClick={ctrler.handleChangeSection_de} />
				</StyAdminPartInputDiv>
				
				<StyAdminPartErrorDiv>
					{ctrler.errorEpty_all ? 'Veuillez remplir les champs obligatoires' : ''}
				</StyAdminPartErrorDiv>
				<StyAdminPartInputDiv>
					<StyAdminSaveInputButton onClick={onCLickSave}>Sauvegarder</StyAdminSaveInputButton>
				</StyAdminPartInputDiv>
			</form>
		</div>
	);
} 

export default AdminManageSection;