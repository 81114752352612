
// import { Contact_ctrl } from '../../../controler/';

import {
	Section,
} from '../../../compoment';

import {
	StyGeneralButtonAddButton,
	StyAdminTitlePartHoverH1,
} from '../../../../style';



function AdminContent({location, sectionList, categoryList, itemList, onClickSection, onClickCategory, onClickItem, onClickAddSection, onClickAddCategory, onClickAddItem, onClickContent, adminPartActive}) {

	// const forCtrler = {
	// };
	// const ctrler = Contact_ctrl(forCtrler);

	return (
		<div>
			<StyAdminTitlePartHoverH1 onClick={onClickContent}>Contenu</StyAdminTitlePartHoverH1>
			<div>
				{
					adminPartActive === 'CONTENT' ? (
						<div>
							<StyGeneralButtonAddButton onClick={onClickAddSection}>Ajouter Section</StyGeneralButtonAddButton>
							{
								sectionList.length !== 0 ? (
									sectionList.map((sectionListItem, liId) => {
										liId++;
										return (
											<div key={liId}>
												<Section
													location={location}
													section={sectionListItem}
													categoryList={categoryList}
													itemList={itemList}
													onClickSection={onClickSection}
													onClickCategory={onClickCategory}
													onClickItem={onClickItem}
													onClickAddCategory={onClickAddCategory}
													onClickAddItem={onClickAddItem} />
											</div>
										)
									})
								) : (<div></div>)
							}
						</div>
					) : (<div></div>)
				}
			</div>
		</div>
	);
} 

export default AdminContent;