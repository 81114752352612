
import styled from 'styled-components';


const StyItemPlaceDiv = styled.div`
`

const StyItemContentDiv = styled.div`
	text-align: center;
`

const StyItemContentImg = styled.img`
	width: 90%;
	max-width: 700px;
`

const StyItemContentTitleH2 = styled.h2`
	font-size: 22px;
	font-weight: 900;
	text-decoration: underline;
`

const StyItemContentDescrP = styled.p`
	margin-top: 10px;
	margin-left: 5%;
	width: 90%;

	font-size: 15px;
	font-weight: 400;

	text-justify: auto;
`

const StyItemContentTagP = styled.p`
	border-top: 1px solid rgb(0, 158, 199);
	border-radius: 10px 10px 0px 0px;
	margin-left: 5%;
	margin-top: 20px;
	margin-bottom: 50px;
	width: 90%;
	padding-top: 10px;

	font-size: 15px;
	font-weight: 400;

	text-decoration: italic;
	text-justify: auto;
`

const StyItemAvailableSpan = styled.span`
	color: ${props => props.available === "true" ? 'rgb(0, 158, 199)' : 'red'};
`

export {
	StyItemPlaceDiv,
	StyItemContentDiv,
	StyItemContentImg,
	StyItemContentTitleH2,
	StyItemContentDescrP,
	StyItemContentTagP,
	StyItemAvailableSpan,
};