
import styled from 'styled-components';


const StySectionPlaceDiv = styled.div`
	margin-top: 20px;
`

const StySectionNameMainH2 = styled.h2`
	margin-top: 0px;
	font-size: 22px;
	font-weight: 900;
	text-decoration: underline;
`

const StySectionNameMenuLeftH4 = styled.span`
	margin-left: 3px;
	margin-top: 0px;
	font-size: 18px;
	font-weight: 900;
	text-decoration: underline;
	color: ${props => props.iscurrent === "true" ? 'rgb(0, 158, 199)' : 'rgb(215, 216, 221)'};
`

const StySectionNameAdminH2 = styled.h2`
	display: inline-block;

	&:hover {
		cursor: pointer;
	}
`

const StySectionCategoryPlaceDiv = styled.div`
	display: inline-block;
`

const StySectionBigCategoryEmptyPlaceDiv = styled.div`
	display: inline-block;
`

const StySectionBigCategoryPlaceDiv = styled.div`
	display: inline-block;
	width: 100px;
	height: 100px;
	margin-left: 20px;
	margin-bottom: 20px;

	${props => props.location === 'ADMIN' ? 'margin-left: 25px;' : ''};
	${props => props.location === 'ADMIN' ? 'margin-bottom: 30px;' : ''};
	${props => props.location === 'ADMIN' && props.isCurrCateAdm === true ? 'display: block;' : ''};
	${props => props.location === 'ADMIN' && props.isCurrCateAdm === true ? 'height: auto' : ''};
	${props => props.location === 'ADMIN' && props.isCurrCateAdm === true ? 'min-height: 100px;' : ''};

	&:hover {
		cursor: pointer;
	}
`

const StySectionLittleCategoryPlaceDiv = styled.div`
	margin-left: 10px;
	width: 90%;
`

const StySectionAdminPartPlaceDiv = styled.div`
`


export {
	StySectionPlaceDiv,
	StySectionNameMainH2,
	StySectionNameMenuLeftH4,
	StySectionNameAdminH2,
	StySectionCategoryPlaceDiv,
	StySectionBigCategoryEmptyPlaceDiv,
	StySectionBigCategoryPlaceDiv,
	StySectionLittleCategoryPlaceDiv,
	StySectionAdminPartPlaceDiv,
};