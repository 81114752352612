
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
	StyFooterPlaceDiv,
	StyFooterTitleH4,
	StyFooterLinkPlaceDiv,
	StyFooterLinkLinkA,
	StyFooterLinkPlanLinkA,
	StyFooterLinkLinkImg,
	StyFooterAddressPlaceDiv,
	StyFooterAddressTextP,
	StyFooterAddressTextStree1Span,
	StyFooterAddressTextStree2Span,
	StyFooterAddressTextCitySpan,
	StyFooterAdresseAdressePlaceDiv,
	StyFooterAdresseDetailPlaceDiv,
	StyFooterAddressTextLittleSpan,
	StyAddressTextLinkA,
	StyFooterAddressMapImg,
	StyFooterAddressMapA,
} from '../../style/';

import {
	globalStore,
	getAdminInfos,
} from '../../globalstate';

import { Footer_ctrl } from '../../controler/';

import logo_ig from '../../img/logo/others/logo_ig.png';
import logo_fb from '../../img/logo/others/logo_fb.png';
import logo_tt from '../../img/logo/others/logo_tt.png';
// import logo_pt from '../../img/logo/others/logo_pt.png';
import gps_point from '../../img/tools/gps_point.png';
import logo_www from '../../img/logo/others/logo_www.png';

const logos = {
	ig: logo_ig,
	fb: logo_fb,
	tt: logo_tt,
	// pt: logo_pt,
};


function Footer() {

	const admin_infos = useSelector(getAdminInfos(globalStore.getState())) || {};

	const forCtrler = {
		admin_infos: admin_infos,
	};
	const ctrler = Footer_ctrl(forCtrler);

	useEffect(() => {
		ctrler.getAdminInfos();
/* eslint-disable */
	}, []);

	const url_gps = ctrler.makeGpsUrl();

	return (
		<StyFooterPlaceDiv>
			<StyFooterLinkPlaceDiv>
				<StyFooterTitleH4>Mes réseaux</StyFooterTitleH4>
				<StyFooterLinkLinkA href={admin_infos.link_instagram} ><StyFooterLinkLinkImg src={logos.ig} alt='instagram' width='20px' height='20px' />Instagram</StyFooterLinkLinkA>
				<StyFooterLinkLinkA href={admin_infos.link_facebook} ><StyFooterLinkLinkImg src={logos.fb} alt='facebook' width='20px' height='20px' />Facebook</StyFooterLinkLinkA>
				<StyFooterLinkLinkA href={admin_infos.link_tiktok} ><StyFooterLinkLinkImg src={logos.tt} alt='tiktok' width='20px' height='20px' />TikTok</StyFooterLinkLinkA>
				<StyFooterLinkPlanLinkA href="/siteplan">Plan du site</StyFooterLinkPlanLinkA>
			</StyFooterLinkPlaceDiv>
			<StyFooterAddressPlaceDiv>
				<StyFooterTitleH4>{admin_infos.shop_name}</StyFooterTitleH4>
				<StyFooterAdresseAdressePlaceDiv>
					{
						admin_infos.shop_address_street2 !== '' ? (
								<StyFooterAddressTextP>
									<StyFooterAddressMapA href={url_gps} target="_blank"><StyFooterAddressMapImg src={gps_point} alt="gps" /></StyFooterAddressMapA>
									<StyFooterAddressTextStree1Span>{admin_infos.shop_address_street1}</StyFooterAddressTextStree1Span>
									<StyFooterAddressTextStree2Span>- {admin_infos.shop_address_street2}</StyFooterAddressTextStree2Span>
								</StyFooterAddressTextP>
							) : (
								<StyFooterAddressTextP>
									<StyFooterAddressMapA href={url_gps}><StyFooterAddressMapImg src={gps_point} alt="gps" /></StyFooterAddressMapA>
									<StyFooterAddressTextStree1Span>{admin_infos.shop_address_street1}</StyFooterAddressTextStree1Span>
								</StyFooterAddressTextP>
							)
					}
					<StyFooterAddressTextP>
						<StyFooterAddressTextCitySpan>{admin_infos.shop_address_cp}</StyFooterAddressTextCitySpan>
						<StyFooterAddressTextCitySpan>{admin_infos.shop_address_city}</StyFooterAddressTextCitySpan>
					</StyFooterAddressTextP>
				</StyFooterAdresseAdressePlaceDiv>
				<StyFooterAdresseDetailPlaceDiv>
					{
						admin_infos.shop_address_tel !== '' ? (
								<StyFooterAddressTextP>
									<StyFooterAddressTextLittleSpan>{ctrler.putTelDot(admin_infos.shop_address_tel)}</StyFooterAddressTextLittleSpan>
								</StyFooterAddressTextP>
							) : (<div></div>)
					}
					{
						admin_infos.shop_address_email !== '' ? (
								<nav>
									<StyFooterAddressTextP>
									<StyFooterAddressTextLittleSpan><StyAddressTextLinkA href="mailto:{admin_infos.shop_address_email}">{admin_infos.shop_address_email}</StyAddressTextLinkA></StyFooterAddressTextLittleSpan>
									</StyFooterAddressTextP>
								</nav>
							) : (<div></div>)
					}
					{
						admin_infos.shop_link !== '' ? (
								<nav>
									<StyFooterAddressTextP>
									<StyFooterAddressTextLittleSpan>
										<StyFooterLinkLinkImg src={logo_www} alt={admin_infos.shop_link} />
										<StyAddressTextLinkA href={admin_infos.shop_link}>Site du shop</StyAddressTextLinkA>
									</StyFooterAddressTextLittleSpan>
									</StyFooterAddressTextP>
								</nav>
							) : (<div></div>)
					}
				</StyFooterAdresseDetailPlaceDiv>
			</StyFooterAddressPlaceDiv>
		</StyFooterPlaceDiv>
	);
} 

export default Footer;


 // <StyFooterLinkLinkA href={admin_infos.link_pinterest} ><StyFooterLinkLinkImg src={logos.pt} alt='pinterest' width='20px' height='20px' />Pinterest</StyFooterLinkLinkA>
