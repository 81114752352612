
import { useSelector } from 'react-redux';
import { useState } from 'react';

import {
	globalStore,
	getAdminInfos,
	getFAdmInf_linkIg,
	getFAdmInf_linkFb,
	getFAdmInf_linkTt,
	getFAdmInf_linkPt,
	getFAdmInf_myEmail,
	getFAdmInf_shopName,
	getFAdmInf_shopSt1,
	getFAdmInf_shopSt2,
	getFAdmInf_shopCp,
	getFAdmInf_shopCt,
	getFAdmInf_shopCo,
	getFAdmInf_shopPh,
	getFAdmInf_shopMa,
	getFAdmInf_shopSi,
} from '../../../../globalstate';

import { AdminManageInfo_ctrl } from '../../../../controler/';

import {
	StyAdminPartTittleH4,
	StyAdminPartInputDiv,
	StyAdminPartInputLabel,
	StyAdminPartInputInput,
	StyAdminSaveInputButton,
	StyAdminPartErrorDiv,
	StyAdminPartErrorSpan,
} from '../../../../style/';

function AdminManageInfo() {

	const admin_infos_old = useSelector(getAdminInfos(globalStore.getState())) || {};

	const adminInfoNew_linkIg = useSelector(getFAdmInf_linkIg(globalStore.getState())) || "";
	const adminInfoNew_linkFb = useSelector(getFAdmInf_linkFb(globalStore.getState())) || "";
	const adminInfoNew_linkTt = useSelector(getFAdmInf_linkTt(globalStore.getState())) || "";
	const adminInfoNew_linkPt = useSelector(getFAdmInf_linkPt(globalStore.getState())) || "";
	const adminInfoNew_myEmail = useSelector(getFAdmInf_myEmail(globalStore.getState())) || "";

	const adminInfoNew_shopName = useSelector(getFAdmInf_shopName(globalStore.getState())) || "";
	const adminInfoNew_shopSt1 = useSelector(getFAdmInf_shopSt1(globalStore.getState())) || "";
	const adminInfoNew_shopSt2 = useSelector(getFAdmInf_shopSt2(globalStore.getState())) || "";
	const adminInfoNew_shopCp = useSelector(getFAdmInf_shopCp(globalStore.getState())) || "";
	const adminInfoNew_shopCt = useSelector(getFAdmInf_shopCt(globalStore.getState())) || "";
	const adminInfoNew_shopCo = useSelector(getFAdmInf_shopCo(globalStore.getState())) || "";
	const adminInfoNew_shopPh = useSelector(getFAdmInf_shopPh(globalStore.getState())) || "";
	const adminInfoNew_shopMa = useSelector(getFAdmInf_shopMa(globalStore.getState())) || "";
	const adminInfoNew_shopSi = useSelector(getFAdmInf_shopSi(globalStore.getState())) || "";

	const [errorEpty_all, setErrorEpty_all] = useState(false);
	const [errorEpty_linkIg, setErrorEpty_linkIg] = useState(false);
	const [errorEpty_linkFb, setErrorEpty_linkFb] = useState(false);
	const [errorEpty_linkTt, setErrorEpty_linkTt] = useState(false);
	const [errorEpty_linkPt, setErrorEpty_linkPt] = useState(false);
	const [errorEpty_myEmail, setErrorEpty_myEmail] = useState(false);
	const [errorEpty_shopName, setErrorEpty_shopName] = useState(false);
	const [errorEpty_shopSt1, setErrorEpty_shopSt1] = useState(false);
	const [errorEpty_shopCp, setErrorEpty_shopCp] = useState(false);
	const [errorEpty_shopCt, setErrorEpty_shopCt] = useState(false);

	const forCtrler = {
		admin_infos_old: admin_infos_old,
		adminInfoNew_linkIg: adminInfoNew_linkIg,
		adminInfoNew_linkFb: adminInfoNew_linkFb,
		adminInfoNew_linkTt: adminInfoNew_linkTt,
		adminInfoNew_linkPt: adminInfoNew_linkPt,
		adminInfoNew_myEmail: adminInfoNew_myEmail,
		adminInfoNew_shopName: adminInfoNew_shopName,
		adminInfoNew_shopSt1: adminInfoNew_shopSt1,
		adminInfoNew_shopSt2: adminInfoNew_shopSt2,
		adminInfoNew_shopCp: adminInfoNew_shopCp,
		adminInfoNew_shopCt: adminInfoNew_shopCt,
		adminInfoNew_shopCo: adminInfoNew_shopCo,
		adminInfoNew_shopPh: adminInfoNew_shopPh,
		adminInfoNew_shopMa: adminInfoNew_shopMa,
		adminInfoNew_shopSi: adminInfoNew_shopSi,
		errorEpty_all: errorEpty_all,
		errorEpty_linkIg: errorEpty_linkIg,
		errorEpty_linkFb: errorEpty_linkFb,
		errorEpty_linkTt: errorEpty_linkTt,
		errorEpty_linkPt: errorEpty_linkPt,
		errorEpty_myEmail: errorEpty_myEmail,
		errorEpty_shopName: errorEpty_shopName,
		errorEpty_shopSt1: errorEpty_shopSt1,
		errorEpty_shopCp: errorEpty_shopCp,
		errorEpty_shopCt: errorEpty_shopCt,
		setErrorEpty_all: setErrorEpty_all,
		setErrorEpty_linkIg: setErrorEpty_linkIg,
		setErrorEpty_linkFb: setErrorEpty_linkFb,
		setErrorEpty_linkTt: setErrorEpty_linkTt,
		setErrorEpty_linkPt: setErrorEpty_linkPt,
		setErrorEpty_myEmail: setErrorEpty_myEmail,
		setErrorEpty_shopName: setErrorEpty_shopName,
		setErrorEpty_shopSt1: setErrorEpty_shopSt1,
		setErrorEpty_shopCp: setErrorEpty_shopCp,
		setErrorEpty_shopCt: setErrorEpty_shopCt,
	};
	const ctrler = AdminManageInfo_ctrl(forCtrler);

	return (
		<div>
			<form>
				<StyAdminPartTittleH4>Liens de contact</StyAdminPartTittleH4>
				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='link_ig'>Instagram :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{errorEpty_linkIg ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputInput type='text' id='link_ig' name='link_ig' value={adminInfoNew_linkIg} onChange={ctrler.handleChange_linkIg} />
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='link_fb'>Facebook :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{errorEpty_linkFb ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputInput type='text' id='link_fb' name='link_fb' value={adminInfoNew_linkFb} onChange={ctrler.handleChange_linkFb} />
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='link_tt'>TikTok :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{errorEpty_linkTt ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputInput type='text' id='link_tt' name='link_tt' value={adminInfoNew_linkTt} onChange={ctrler.handleChange_linkTt} />
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='link_pt'>Pinterest :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{errorEpty_linkPt ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputInput type='text' id='link_pt' name='link_pt' value={adminInfoNew_linkPt} onChange={ctrler.handleChange_linkPt} />
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='my_mail'>Mon E-mail :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{errorEpty_myEmail ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputInput type='text' id='my_mail' name='my_mail' value={adminInfoNew_myEmail} onChange={ctrler.handleChange_myEmail} />
				</StyAdminPartInputDiv>

				<StyAdminPartTittleH4>Shop</StyAdminPartTittleH4>
				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='shop_name'>Nom :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{errorEpty_shopName ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputInput type='text' id='shop_name' name='shop_name' value={adminInfoNew_shopName} onChange={ctrler.handleChange_shopName} />
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='address_st1'>N° et rue :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{errorEpty_shopSt1 ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputInput type='text' id='address_st1' name='address_st1' value={adminInfoNew_shopSt1} onChange={ctrler.handleChange_shopSt1} />
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='address_st2'>Complément :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{''}</StyAdminPartErrorSpan>
					<StyAdminPartInputInput type='text' id='address_st2' name='address_st2' value={adminInfoNew_shopSt2} onChange={ctrler.handleChange_shopSt2} />
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='address_cp'>Code postale :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{errorEpty_shopCp ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputInput type='text' id='address_cp' name='address_cp' value={adminInfoNew_shopCp} onChange={ctrler.handleChange_shopCp} />
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='address_city'>Ville :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{errorEpty_shopCt ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputInput type='text' id='address_city' name='address_city' value={adminInfoNew_shopCt} onChange={ctrler.handleChange_shopCt} />
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='address_ph'>Téléphone :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{''}</StyAdminPartErrorSpan>
					<StyAdminPartInputInput type='text' id='address_ph' name='address_ph' value={adminInfoNew_shopPh} onChange={ctrler.handleChange_shopPh} />
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='address_ma'>E-mail :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{''}</StyAdminPartErrorSpan>
					<StyAdminPartInputInput type='text' id='address_ma' name='address_ma' value={adminInfoNew_shopMa} onChange={ctrler.handleChange_shopMa} />
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='address_si'>Site :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{''}</StyAdminPartErrorSpan>
					<StyAdminPartInputInput type='text' id='address_si' name='address_si' value={adminInfoNew_shopSi} onChange={ctrler.handleChange_shopSi} />
				</StyAdminPartInputDiv>

				<StyAdminPartErrorDiv>
					{errorEpty_all ? 'Veuillez remplir les champs obligatoires' : ''}
				</StyAdminPartErrorDiv>
				<StyAdminPartInputDiv>
					<StyAdminSaveInputButton onClick={ctrler.handleSaveInfo}>Sauvegarder</StyAdminSaveInputButton>
				</StyAdminPartInputDiv>
			</form>
		</div>
	);
} 

export default AdminManageInfo;

// shop_address_coord