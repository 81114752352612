
// import { Contact_ctrl } from '../../../controler/';

import {
	StyGeneralButtonAddButton,
	StyAdminTitlePartH1,
} from '../../../../style';


function AdminInfo({onClickInfo}) {

	// const forCtrler = {
	// };
	// const ctrler = Contact_ctrl(forCtrler);

	return (
		<div>
			<StyAdminTitlePartH1>Liens & Adresse</StyAdminTitlePartH1>
			<StyGeneralButtonAddButton onClick={onClickInfo}>Modifier</StyGeneralButtonAddButton>
		</div>
	);
} 

export default AdminInfo;