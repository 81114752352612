
import { Getter } from '../../../../generaltools';

import {
	StyCatagoryItemBigPlaceFigure,
	StyCatagoryItemBigImgImg,
	StyCatagoryItemBigLegendFigcaption,
	StyCatagoryItemBigLegendSpan,
} from '../../../../style/';

function CategoryItemBig({location, category_item, onClick, setCategoryName}) {

	return (
		<StyCatagoryItemBigPlaceFigure onClick={onClick}>
			<StyCatagoryItemBigImgImg src={Getter.getImgPath(category_item.img_path)} alt={category_item.name} />
			<StyCatagoryItemBigLegendFigcaption><StyCatagoryItemBigLegendSpan>{setCategoryName(category_item.name, location)}</StyCatagoryItemBigLegendSpan></StyCatagoryItemBigLegendFigcaption>
		</StyCatagoryItemBigPlaceFigure>
	);
}

export default CategoryItemBig;