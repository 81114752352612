
import styled from 'styled-components';

const StyCategoryPlaceDiv = styled.div`
`

const StyCategoryTitleH2 = styled.h2`
	font-size: 22px;
	font-weight: 900;
	text-decoration: underline;
`

const StyCategoryItemListPlaceDiv = styled.main`
	margin-left: 10px;
	margin-top: 30px;
`

const StyCategoryItemPlaceDiv = styled.div`
	display: inline-block;
`

export {
	StyCategoryPlaceDiv,
	StyCategoryTitleH2,
	StyCategoryItemPlaceDiv,
	StyCategoryItemListPlaceDiv,
};