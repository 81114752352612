
import { useSelector } from 'react-redux';
import { useState } from 'react';

import { Getter } from '../../../../generaltools';

import {
	globalStore,
	getActuPara,
	getFActuPara_ti,
	getFActuPara_dc,
	getFActuPara_ip,
	getFActuPara_ds,
	getFActuPara_de,
	getFActuPara_sa,
	getFActuPara_ad,
	getFActuPara_cp,
	getFActuPara_ci,
} from '../../../../globalstate';

import { AdminManageActu_ctrl } from '../../../../controler/';

import {
	StyAdminPartTittleH4,
	StyAdminPartInputDiv,
	StyAdminPartInputLabel,
	StyAdminPartInputInput,
	StyAdminSaveInputButton,
	StyAdminPartErrorDiv,
	StyAdminPartErrorSpan,
	StyAdminPartInputTextareaLabel,
	StyAdminPartInputTextarea,
	StyAdminPartInputImg,
	StyAdminPartInputRadioLabel,
	StyAdminPartInputRadioInput,
} from '../../../../style/';

function AdminManageActu() {

	const actu_old = useSelector(getActuPara(globalStore.getState())) || {};

	const actuPara_ti = useSelector(getFActuPara_ti(globalStore.getState())) || "";
	const actuPara_dc = useSelector(getFActuPara_dc(globalStore.getState())) || "";
	const actuPara_ip = useSelector(getFActuPara_ip(globalStore.getState())) || "";
	const actuPara_ds = useSelector(getFActuPara_ds(globalStore.getState())) || "";
	const actuPara_de = useSelector(getFActuPara_de(globalStore.getState())) || "";
	let actuPara_sa = useSelector(getFActuPara_sa(globalStore.getState()));
	const actuPara_ad = useSelector(getFActuPara_ad(globalStore.getState())) || "";
	const actuPara_cp = useSelector(getFActuPara_cp(globalStore.getState())) || "";
	const actuPara_ci = useSelector(getFActuPara_ci(globalStore.getState())) || "";

	const [errorEpty_all, setErrorEpty_all] = useState(false);
	const [errorEpty_ti, setErrorEpty_ti] = useState(false);
	const [errorEpty_dc, setErrorEpty_dc] = useState(false);
	const [errorEpty_ds, setErrorEpty_ds] = useState(false);
	const [errorEpty_de, setErrorEpty_de] = useState(false);

	const forCtrler = {
		actu_old: actu_old,
		actuPara_ti: actuPara_ti,
		actuPara_dc: actuPara_dc,
		actuPara_ip: actuPara_ip,
		actuPara_ds: actuPara_ds,
		actuPara_de: actuPara_de,
		actuPara_sa: actuPara_sa,
		actuPara_ad: actuPara_ad,
		actuPara_cp: actuPara_cp,
		actuPara_ci: actuPara_ci,
		errorEpty_all: errorEpty_all,
		errorEpty_ti: errorEpty_ti,
		errorEpty_dc: errorEpty_dc,
		errorEpty_ds: errorEpty_ds,
		errorEpty_de: errorEpty_de,
		setErrorEpty_all: setErrorEpty_all,
		setErrorEpty_ti: setErrorEpty_ti,
		setErrorEpty_dc: setErrorEpty_dc,
		setErrorEpty_ds: setErrorEpty_ds,
		setErrorEpty_de: setErrorEpty_de,
	};
	const ctrler = AdminManageActu_ctrl(forCtrler);

	if (actuPara_sa === undefined) {
		actuPara_sa = true;
	}
	
	return (
		<div>
			<form>
				<StyAdminPartTittleH4>Actualité</StyAdminPartTittleH4>
				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='actu_ti'>Titre :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{errorEpty_ti ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputInput type='text' id='actu_ti' name='actu_ti' value={actuPara_ti} onChange={ctrler.handleChange_ti} />
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputTextareaLabel htmlFor='actu_dc'>Description :</StyAdminPartInputTextareaLabel>
					<StyAdminPartErrorSpan>{errorEpty_dc ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputTextarea id='actu_dc' name='actu_dc' value={actuPara_dc} onChange={ctrler.handleChange_dc}>
					</StyAdminPartInputTextarea>
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='actu_ip'>Image :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{''}</StyAdminPartErrorSpan>
					<StyAdminPartInputInput type='file' id='actu_ip' name='actu_ip' onChange={ctrler.handleChange_ip} />
					<StyAdminPartInputImg src={actuPara_ip.service_url || Getter.getImgPath(actuPara_ip)} alt='imgUploaded' />
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='actu_ds'>Date début :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{errorEpty_ds ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputInput type='text' id='actu_ds' name='actu_ds' value={actuPara_ds} onChange={ctrler.handleChange_ds} />
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='actu_de'>Date fin :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{errorEpty_de ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputInput type='text' id='actu_de' name='actu_de' value={actuPara_de} onChange={ctrler.handleChange_de} />
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel>Evenement au shop :</StyAdminPartInputLabel>
					<StyAdminPartInputRadioLabel htmlFor='evtshop_y_actu'>Oui</StyAdminPartInputRadioLabel>
					{ actuPara_sa === true ? (
							<StyAdminPartInputRadioInput type='radio' id='evtshop_y_actu' name='evtshop_item' value='Y' defaultChecked onClick={ctrler.handleChange_sa} />
						) : (
							<StyAdminPartInputRadioInput type='radio' id='evtshop_y_actu' name='evtshop_item' value='Y' onClick={ctrler.handleChange_sa} />
						)
					}
					<StyAdminPartInputRadioLabel htmlFor='evtshop_n_actu'>Non</StyAdminPartInputRadioLabel>
					{ actuPara_sa === true ? (
							<StyAdminPartInputRadioInput type='radio' id='evtshop_n_actu' name='evtshop_item' value='N' onClick={ctrler.handleChange_sa} />
						) : (
							<StyAdminPartInputRadioInput type='radio' id='evtshop_n_actu' name='evtshop_item' value='N' defaultChecked onClick={ctrler.handleChange_sa} />
						)
					}
				</StyAdminPartInputDiv>

				{
					actuPara_sa === false ? (
						<div>
							<StyAdminPartInputDiv>
								<StyAdminPartInputLabel htmlFor='actu_ad'>N° et rue :</StyAdminPartInputLabel>
								<StyAdminPartErrorSpan>{''}</StyAdminPartErrorSpan>
								<StyAdminPartInputInput type='text' id='actu_ad' name='actu_ad' value={actuPara_ad} onChange={ctrler.handleChange_ad} />
							</StyAdminPartInputDiv>

							<StyAdminPartInputDiv>
								<StyAdminPartInputLabel htmlFor='actu_cp'>Code postale :</StyAdminPartInputLabel>
								<StyAdminPartErrorSpan>{''}</StyAdminPartErrorSpan>
								<StyAdminPartInputInput type='text' id='actu_cp' name='actu_cp' value={actuPara_cp} onChange={ctrler.handleChange_cp} />
							</StyAdminPartInputDiv>

							<StyAdminPartInputDiv>
								<StyAdminPartInputLabel htmlFor='actu_ci'>Ville :</StyAdminPartInputLabel>
								<StyAdminPartErrorSpan>{''}</StyAdminPartErrorSpan>
								<StyAdminPartInputInput type='text' id='actu_ci' name='actu_ci' value={actuPara_ci} onChange={ctrler.handleChange_ci} />
							</StyAdminPartInputDiv>
						</div>
					) : ''
				}

				<StyAdminPartErrorDiv>
					{errorEpty_all ? 'Veuillez remplir les champs obligatoires' : ''}
				</StyAdminPartErrorDiv>
				<StyAdminPartInputDiv>
					<StyAdminSaveInputButton onClick={ctrler.handleSaveActu}>Sauvegarder</StyAdminSaveInputButton>
				</StyAdminPartInputDiv>
			</form>
		</div>
	);
} 

export default AdminManageActu;
