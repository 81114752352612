
// import { useNavigate } from 'react-router-dom';
import { ItemItem } from '../../';

import { Getter } from '../../../../generaltools';

import {
	StyGeneralButtonAddButton,
	StyAdminPartImgItemPlaceDiv,
	StyAdminPartImgCateDiv,
	StyAdminPartImgItemDiv,
	StyAdminPartImgCateImg,
	StyCatagoryItemBigPlaceFigure,
} from '../../../../style/';

function CategoryItemAdmin({location, category_item, itemList, onClickCategory, onClickItem, onClickAddItem, setCategoryName, isAdminCurrentCategory}) {

	return (
		<StyAdminPartImgCateDiv>
			<StyCatagoryItemBigPlaceFigure onClick={onClickCategory} location={location}>
				<StyAdminPartImgCateImg src={Getter.getImgPath(category_item.img_path)} alt={category_item.name} />
				<figcaption>{setCategoryName(category_item.name, location)}</figcaption>
			</StyCatagoryItemBigPlaceFigure>
			{
				location === 'ADMIN' && isAdminCurrentCategory() ? (
					<StyAdminPartImgItemPlaceDiv>
						<StyGeneralButtonAddButton onClick={onClickAddItem}>Ajouter Poste</StyGeneralButtonAddButton>
						{
							itemList.length !== 0 ? (
								itemList.map((ItemItemListItem, liId) => {
									liId++;
									if (ItemItemListItem.category_id === category_item.id) {
										return (
											<StyAdminPartImgItemDiv key={liId}>
												<ItemItem
													location={location}
													item={ItemItemListItem}
													onClickItem={onClickItem} />
											</StyAdminPartImgItemDiv>
										)
									} else { return (<div key={liId}></div>) }
								})
							) : (<div></div>)
						}
					</StyAdminPartImgItemPlaceDiv>
				) : (<div></div>)
			}
		</StyAdminPartImgCateDiv>
	);
}

export default CategoryItemAdmin;
