
function MenuHeader_ctrl(forCtrler) {

	const hamdleClickOnWelcome = () => {
		forCtrler.navigate("/welcome");
	};

	const hamdleClickOnAbout = () => {
		const linkAbout = "/about/" + forCtrler.aboutLast.slug;

		forCtrler.navigate(linkAbout);
	};

	const hamdleClickOnContact = () => {
		forCtrler.navigate("/contact");
	};

	const hamdleClickOnActu = () => {
		forCtrler.navigate("/actu");
	};


	return {
		hamdleClickOnWelcome: hamdleClickOnWelcome,
		hamdleClickOnAbout: hamdleClickOnAbout,
		hamdleClickOnContact: hamdleClickOnContact,
		hamdleClickOnActu: hamdleClickOnActu,
	};
} 

export default MenuHeader_ctrl;