
import { defaultQueryFn } from '../../queryapi';

import {
	globalStore,
	setAdminInfos,
} from '../../globalstate';


function Footer_ctrl(forCtrler) {

	const getAdminInfos = async () => {
		await defaultQueryFn({
			query: "admin_infos",
			typeReq: "GET",
			args: {},
			enable: true,
			special: null
		})
			.then((result) => {
				globalStore.dispatch(setAdminInfos(
					result && result.data && result.data.result ? result.data.result[0] : null
				));
				return result;
			})
			.catch((error) => {
				return error;
			})
	};

	const putTelDot = (tel) => {
		if (tel) {
			if (tel.includes('.')) {
				return tel;
			} else {
				if (tel.includes('+')) { // a revoir si international
					return tel.substring(0, 3)
						+ '.'
						+ tel.substring(3, 5)
						+ '.'
						+ tel.substring(5, 7)
						+ '.'
						+ tel.substring(7, 9)
						+ '.'
						+ tel.substring(9, 11);
				} else {
					return tel.substring(0, 2)
						+ '.'
						+ tel.substring(2, 4)
						+ '.'
						+ tel.substring(4, 6)
						+ '.'
						+ tel.substring(6, 8)
						+ '.'
						+ tel.substring(8, 10);
				}
			}
		} else {
			return tel;
		}
	};

	const makeGpsUrl = () => {
		const gpsUrl = 'https://www.google.com/maps/dir//'
			+ forCtrler.admin_infos.shop_address_street1 + ',+'
			+ forCtrler.admin_infos.shop_address_cp + '+'
			+ forCtrler.admin_infos.shop_address_city + '/';
			// + forCtrler.admin_infos.shop_address_coord;

		return gpsUrl;
	};		

	return {
		getAdminInfos: getAdminInfos,
		putTelDot: putTelDot,
		makeGpsUrl: makeGpsUrl,
	};
} 

export default Footer_ctrl;