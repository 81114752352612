
import { defaultQueryFn } from '../../../../queryapi';

import {
	globalStore,
	setActuPara,
	setFActuPara_ti,
	setFActuPara_dc,
	// setFActuPara_ip,
	setFActuPara_ds,
	setFActuPara_de,
	setFActuPara_sa,
	setFActuPara_ad,
	setFActuPara_cp,
	setFActuPara_ci,
} from '../../../../globalstate';

function AdminManageActu_ctrl(forCtrler) {

	const checkErrorGeneral = (fromBtSave) => {
		let errorAll = false;

		if (forCtrler.errorEpty_ti === true || forCtrler.actuPara_ti === "") {
			if (forCtrler.actuPara_ti === "" && fromBtSave) {
				forCtrler.setErrorEpty_ti(true);
			}
			errorAll = true;
		}
		
		if (forCtrler.errorEpty_dc === true || forCtrler.actuPara_dc === "") {
			if (forCtrler.actuPara_dc === "" && fromBtSave) {
				forCtrler.setErrorEpty_dc(true);
			}
			errorAll = true;
		}
		
		if (forCtrler.errorEpty_ds === true || forCtrler.actuPara_ds === "") {
			if (forCtrler.actuPara_ds === "" && fromBtSave) {
				forCtrler.setErrorEpty_ds(true);
			}
			errorAll = true;
		}
		
		if (forCtrler.errorEpty_de === true || forCtrler.actuPara_de === "") {
			if (forCtrler.actuPara_de === "" && fromBtSave) {
				forCtrler.setErrorEpty_de(true);
			}
			errorAll = true;
		}
		
		forCtrler.setErrorEpty_all(errorAll);
		forCtrler.errorEpty_all = errorAll;
	};

	const handleSaveActu = async (event) => {
		event.preventDefault();

		checkErrorGeneral(true);

		if (forCtrler.errorEpty_all === false) {
			// let req = 'https://nominatim.openstreetmap.org/search?format=json&q='
			// 	+ forCtrler.adminInfoNew_shopSt1 + ' '
			// 	+ forCtrler.adminInfoNew_shopCp + ' '
			// 	+ forCtrler.adminInfoNew_shopCt + ' ';

			// // fetch('https://nominatim.openstreetmap.org/search?format=json&q=nozay 91620')
			// fetch(req)
			// 	.then(response => response.json())
			// 	.then(data => {
			// 		forCtrler.adminInfoNew_shopCo = '@'
			// 			+ data[0].lat + ','
			// 			+ data[0].lon + ',10z';
			// })
			let shop_addr = true;
			if (forCtrler.actuPara_sa !== undefined) {
				shop_addr = forCtrler.actuPara_sa;
			}
			

			await defaultQueryFn({
				query: "actus/1",
				typeReq: "PUT",
				args: {
					title: forCtrler.actuPara_ti,
					descr: forCtrler.actuPara_dc,
					img_path: forCtrler.actuPara_ip,
					dt_start: forCtrler.actuPara_ds,
					dt_end: forCtrler.actuPara_de,
					shop_addr: shop_addr,
					loc_addr: forCtrler.actuPara_addr,
					loc_cp: forCtrler.actuPara_cp,
					loc_city: forCtrler.actuPara_ci,
				},
				enable: true,
				special: null
			})
				.then((result) => {
					globalStore.dispatch(setActuPara(
						result && result.data && result.data.result ? result.data.result : null
					));
					return result;
				})
				.catch((error) => {
					return error;
				})
		}
	};

	const handleChange_ti = (event) => {
		// forCtrler.setAdminInfoNew_linkIg(event.target.value);
		if (event.target.value === "") {
			forCtrler.setErrorEpty_ti(true);
		} else {
			forCtrler.setErrorEpty_ti(false);
		}
		checkErrorGeneral(false);
		globalStore.dispatch(setFActuPara_ti(event.target.value));
	};

	const handleChange_dc = (event) => {
		// forCtrler.setAdminInfoNew_linkFb(event.target.value);
		if (event.target.value === "") {
			forCtrler.setErrorEpty_dc(true);
		} else {
			forCtrler.setErrorEpty_dc(false);
		}
		globalStore.dispatch(setFActuPara_dc(event.target.value));
	};

	const handleChange_ip = async (event) => {
		// forCtrler.setAdminInfoNew_linkTt(event.target.value);
		await defaultQueryFn({
				query: "NONE",
				typeReq: "FILE",
				args: event.target.files[0],
				enable: true,
				special: { typeItem: 'ACTU' }
			})
				.then((result) => {
					return result;
				})
				.catch((error) => {
					return error;
				})
	};

	const handleChange_ds = (event) => {
		// forCtrler.setAdminInfoNew_linkPt(event.target.value);
		if (event.target.value === "") {
			forCtrler.setErrorEpty_ds(true);
		} else {
			forCtrler.setErrorEpty_ds(false);
		}
		checkErrorGeneral(false);
		globalStore.dispatch(setFActuPara_ds(event.target.value));
	};

	const handleChange_de = (event) => {
		// forCtrler.setAdminInfoNew_myEmail(event.target.value);
		if (event.target.value === "") {
			forCtrler.setErrorEpty_de(true);
		} else {
			forCtrler.setErrorEpty_de(false);
		}
		checkErrorGeneral(false);
		globalStore.dispatch(setFActuPara_de(event.target.value));
	};

	const handleChange_sa = (event) => {
		// forCtrler.setAdminInfoNew_shopName(event.target.value);
		if (event.target.value === 'N') {
			globalStore.dispatch(setFActuPara_sa(false));
		} else {
			globalStore.dispatch(setFActuPara_sa(true));
		}
	};

	const handleChange_ad = (event) => {
		// forCtrler.setAdminInfoNew_shopSt1(event.target.value);
		globalStore.dispatch(setFActuPara_ad(event.target.value));
	};

	const handleChange_cp = (event) => {
		// forCtrler.setAdminInfoNew_shopSt2(event.target.value);
		globalStore.dispatch(setFActuPara_cp(event.target.value));
	};

	const handleChange_ci = (event) => {
		// forCtrler.setAdminInfoNew_shopCp(event.target.value);
		globalStore.dispatch(setFActuPara_ci(event.target.value));
	};


	return {
		handleSaveActu: handleSaveActu,
		handleChange_ti: handleChange_ti,
		handleChange_dc: handleChange_dc,
		handleChange_ip: handleChange_ip,
		handleChange_ds: handleChange_ds,
		handleChange_de: handleChange_de,
		handleChange_sa: handleChange_sa,
		handleChange_ad: handleChange_ad,
		handleChange_cp: handleChange_cp,
		handleChange_ci: handleChange_ci,
	};
} 

export default AdminManageActu_ctrl;