
import { useSelector } from 'react-redux';

import {
	globalStore,
	getAboutParagraphs,
} from '../../../globalstate';

import { Getter } from '../../../generaltools';

import { HelmetComp } from '../../compoment/';

function Siteplan({elementsTree, aboutAll}) {

	const headerPage = Getter.getHeaderPage({pageName: 'SITEPLAN'});

	return (
		<article>
			<HelmetComp
				title = {headerPage.title}
				description = {headerPage.descr}
				image = {headerPage.img} />
			<header>
				<h1>Plan du site</h1>
			</header>
			<main>
				<nav>
					<a href="/"><h2>Accueil</h2></a>
					<hr/>
					<a href="/welcome"><h2>Accueil</h2></a>
					<hr/>
					<a href="/actu"><h2>Actualités</h2></a>
					<hr/>
					<h2>A propos</h2>
					{
						aboutAll.length !== 0 && aboutAll.map((about) => {
							let aboutLink = "/about/" + about.slug;
							return (
								<div>
									<a href={aboutLink}><h3>{about.title}</h3></a>
								</div>
							);
						})
					}
					<hr/>
					<a href="/contact"><h2>Contact</h2></a>
					<hr/>
					<h2>Postes</h2>
					{
						elementsTree.sections.map((section) => {
							return (
								<div>
									<h3>{section.name}</h3>
									{
										elementsTree.categories.map((category) => {
											if (category.section_id === section.id) {
												let categoryLink = "/category/" + category.slug;
												return (
													<div>
														<a href={categoryLink}><h4>{category.name}</h4></a>
														{
															elementsTree.items.map((item) => {
																if (item.category_id === category.id) {
																	let itemLink = "/item/" + item.slug;
																	return (
																		<div>
																			<a href={itemLink}><h5>{item.title}</h5></a>
																		</div>
																	);
																} else { return (<div></div>); }
															})
														}
													</div>
												);
											} else { return (<div></div>); }
										})
									}
								</div>
							);
						})
					}
				</nav>
			</main>
		</article>
	);
};

export default Siteplan;

