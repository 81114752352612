
// import { Contact_ctrl } from '../../../controler/';
import { About } from '../../';

import {
	StyAdminTitlePartHoverH1,
} from '../../../../style';

function AdminAbout({location, aboutText, onClickAboutText, onClickAddAboutText, onClickAbout, adminPartActive}) {

	// const forCtrler = {
	// };
	// const ctrler = Contact_ctrl(forCtrler);

	return (
		<div>
			<StyAdminTitlePartHoverH1 onClick={onClickAbout}>À propos</StyAdminTitlePartHoverH1>
			{
				adminPartActive === 'ABOUT' ? (
					<div>
						<About
							location={location}
							aboutText={aboutText}
							onClickAboutText={onClickAboutText}
							onClickAddAboutText={onClickAddAboutText} />
					</div>
				) : (<div></div>)
			}
		</div>
	);
} 

export default AdminAbout;