
import styled from 'styled-components';


const StyContactPlaceDiv = styled.article`
`

const StyContactLinksDiv = styled.main`
	text-align: center;
	margin-bottom: 50px;
`

const StyContactLinksP = styled.p`
	font-size: 15px;
	font-weight: 400;
`

const StyContactLinksSpan = styled.span`
	font-style: italic;
`

const StyContactFormDiv = styled.div`
	text-align: center;
`

const StyContactFormForm = styled.form`
	margin-bottom: 200px;
	border-top: rgb(0, 158, 199) 1px solid;
	border-radius: 15px 15px 0px 0px;
	padding-top: 20px;
`

const StyContactFormLabel = styled.label`
	display: block;
	margin-bottom: 20px;
`

const StyContactFormLabelSpan = styled.span`
	font-size: 15px;
	font-weight: 900;
	text-decoration: underline;
	margin-right: 10px;
`

const StyContactFormLabelInput = styled.input`
	font-size: 15px;
	font-weight: 400;
`

const StyContactFormLabelTextarea = styled.textarea`
	width: 250px;
	height: 150px;
	font-size: 15px;
	font-weight: 400;
`

const StyContactFormButton = styled.button`
	border: 1px solid red;
	padding: 7px;

	font-size: 20px;
	font-weight: 900;
	color: rgb(215, 216, 221);

	border: rgb(0, 158, 199) 2px solid;
	border-radius: 15px 15px 15px 15px;

	background-color: rgb(18, 21, 25);

	&:hover {
		cursor: pointer;
		color: rgb(0, 158, 199);
	}
`


export {
	StyContactPlaceDiv,
	StyContactLinksDiv,
	StyContactLinksP,
	StyContactLinksSpan,
	StyContactFormDiv,
	StyContactFormForm,
	StyContactFormLabel,
	StyContactFormLabelSpan,
	StyContactFormLabelInput,
	StyContactFormLabelTextarea,
	StyContactFormButton,
};