
import { useSelector } from 'react-redux';

import {
	globalStore,
	getActuPara,
	getAdminInfos,
} from '../../../globalstate';

import { Getter } from '../../../generaltools';

import { HelmetComp } from '../../compoment/';

import {
	StyGeneralPageArticle,
	StyGeneralPageHeader,
	StyGeneralPageH1,
	StyGeneralPageMain,
	StyActuPageDiv,
	StyActuImgImg,
	StyActuDateP,
	StyActuRdvP,
	StyActuTextP,
} from '../../../style/';

function Actu({location, actuText, onClickActuManage}) {

	const actuPara = useSelector(getActuPara(globalStore.getState())) || {};

	const admin_infos = useSelector(getAdminInfos(globalStore.getState())) || {};
	
	const headerPage = Getter.getHeaderPage({pageName: 'ACTU', info: admin_infos, actuPara: actuPara});
	
	const datetime_start = Getter.getDatetime(actuPara.dt_start);
	const datetime_end = Getter.getDatetime(actuPara.dt_end);


	return (
		<StyGeneralPageArticle>
			<HelmetComp
				title = {headerPage.title}
				description = {headerPage.descr}
				image = {headerPage.img} />
			<StyGeneralPageHeader>
				<StyGeneralPageH1>{headerPage.title}</StyGeneralPageH1>
			</StyGeneralPageHeader>
			<StyGeneralPageMain>
				<StyActuPageDiv>
					{
						Getter.menuDisplayActu(actuPara) === true && actuPara.img_path !== undefined ? (
								<figure>
								  <StyActuImgImg src={headerPage.img} alt={headerPage.descr} />
								  <figcaption>{headerPage.title}</figcaption>
								</figure>
							) : ''
					}
					{
						Getter.menuDisplayActu(actuPara) === true ? (
								<StyActuDateP>
									Du <time datetime={datetime_start}>{actuPara.dt_start}</time> au <time datetime={datetime_end}>{actuPara.dt_end}</time>
								</StyActuDateP>
							) : ''
					}
					{
						Getter.menuDisplayActu(actuPara) === true ? (
							<div>
								{
									actuPara.shop_addr && actuPara.shop_addr === true ? (
										<StyActuRdvP>Rendez-vous au {admin_infos.shop_name}</StyActuRdvP>
									) : (
										<StyActuRdvP>Rendez-vous au {actuPara.loc_addr}, {actuPara.loc_cp} {actuPara.loc_city}</StyActuRdvP>
									)
								}
							</div>
						) : ''
					}
					{
						Getter.menuDisplayActu(actuPara) === true ? (<StyActuTextP>{actuPara.descr}</StyActuTextP>) : (<StyActuTextP>{headerPage.descr}</StyActuTextP>)
					}
				</StyActuPageDiv>
			</StyGeneralPageMain>
		</StyGeneralPageArticle>
	);
} 

export default Actu;