
import styled from 'styled-components';


const StyMenuLeftPlaceDiv = styled.nav`
	border-top: 1px solid rgb(0, 158, 199);
	border-right: 1px solid rgb(0, 158, 199);
	border-radius: 10px 25px 10px 0px;

	width: 145px;
	margin-left: 5px;
	float: left;
`

const StyMenuLeftLinkAboutDiv = styled.div`
	font-size: 15px;
	font-weight: 900;
	margin-top: 10px;

	color: ${props => props.isCurrent === true ? 'rgb(0, 158, 199)' : '1px solid rgb(215, 216, 221)'};

	&:hover {
		cursor: pointer;
	}
`



export {
	StyMenuLeftPlaceDiv,
	StyMenuLeftLinkAboutDiv,
};