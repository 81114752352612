
import styled from 'styled-components';

const StyWelcomePlaceDiv = styled.article`
	margin-left: 5px;
	margin-bottom: 50px;
`

const StyWelcomeSectionPlaceDiv = styled.div`
	margin-left: 10px;
`


export {
	StyWelcomePlaceDiv,
	StyWelcomeSectionPlaceDiv,
};
