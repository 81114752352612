
import styled from 'styled-components';


const StyCatagoryItemPlaceDiv = styled.div`
	width: 100px;
	margin-bottom: 5px;
	border-radius: 25px 25px 25px 25px;
`

const StyCatagoryItemBigPlaceFigure = styled.figure`
	width: 100px;
	height: 100px;
	margin-top: 0px;
	margin-left: 0px;
	border: 2px solid rgb(0, 158, 199);
	border-radius: 25px 25px 25px 25px;

	${props => props.location === 'ADMIN' ? 'border: none' : ''};
	${props => props.location === 'ADMIN' && props.isCurrCateAdm === true ? 'display: inline;' : ''};
`

const StyCatagoryItemBigImgImg = styled.img`
	width: 100px;
	height: 100px;
	border-radius: 25px 25px 25px 25px;
`

const StyCatagoryItemBigLegendFigcaption = styled.figcaption`
	width: 100px;
	max-height: 17px;
	margin-top: -23px;
	margin-left: 5px;
`

const StyCatagoryItemBigLegendSpan = styled.span`
	display: inline-block;
	width: 90px;
	max-height: 17px;

	text-align: center;
	background-color: rgb(18, 21, 25);
	border-radius: 0px 0px 50px 50px;

	font-size: 15px;
	font-weight: 700;
`

const StyCatagoryItemLittlePlaceDiv = styled.div`
	width: 127px;
`

const StyCatagoryItemLittleContentDiv = styled.div`
	width: 134px;
	&:hover {
		cursor: pointer;
	}
`

const StyCatagoryItemLittleContentImg = styled.img`
	border: ${props => props.iscurrent === "true" ? '2px solid rgb(0, 158, 199)' : '2px solid rgb(215, 216, 221)'};
	border-radius: 5px 5px 5px 5px;

	width: 20px;
	height: 20px;
	display: inline-block;

	margin-right: 2px;
`

const StyCatagoryItemLittleContentSpan = styled.div`
	display: inline-block;

	width: auto;
	height: 20px;

	-ms-transform: translateY(-30%);
	transform: translateY(-30%);


	font-size: 15px;
	font-weight: 700;
`

const StyCatagoryItemLittleItemPlaceDiv = styled.div`
	display: inline-block;
`

const StyCategoryItemLittleAdmItemListDiv = styled.div`

`

export {
	StyCatagoryItemPlaceDiv,
	StyCatagoryItemBigPlaceFigure,
	StyCatagoryItemBigImgImg,
	StyCatagoryItemBigLegendFigcaption,
	StyCatagoryItemBigLegendSpan,
	StyCatagoryItemLittlePlaceDiv,
	StyCatagoryItemLittleContentDiv,
	StyCatagoryItemLittleContentImg,
	StyCatagoryItemLittleContentSpan,
	StyCatagoryItemLittleItemPlaceDiv,
	StyCategoryItemLittleAdmItemListDiv,
};