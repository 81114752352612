
function Carousel_ctrl(forCtrler) {

	const handleCarouselRespOnClickItem = (event) => {
		// var link = "/category/" + forCtrler.category_item.id;

		// const category = Getter.getCategory(forCtrler.item.category_id, forCtrler.allCategories);

		// globalStore.dispatch(setCurrentItem({item: forCtrler.item}));
		// globalStore.dispatch(setCurrentCategory({category: category}));
		// globalStore.dispatch(setCurrentSection({section: Getter.getSection(category.section_id, forCtrler.allSection)}));

		const carouItem = getCarouselItemsOrdered();

		const routeItem = '/item/' + carouItem[event].slug;

		forCtrler.navigate(routeItem);
	};

	const getCarouselItems = () => {
		let carouselItems = [];

		forCtrler.carouselItems.forEach((carouselItem) => {
			if (parseInt(carouselItem.carousel_order) !== 0) {
				carouselItems.push(carouselItem);
			}
		});

		return carouselItems;
	};

	const getCarouselItemsOrdered = () => {
		const carouselItems = getCarouselItems();

		const compare = ( a, b ) => {
		  if ( a.carousel_order < b.carousel_order ){
		    return -1;
		  }
		  if ( a.carousel_order > b.carousel_order ){
		    return 1;
		  }
		  return 0;
		}

		carouselItems.sort( compare );

		return carouselItems;
	};


	return {
		handleCarouselRespOnClickItem: handleCarouselRespOnClickItem,
		getCarouselItemsOrdered: getCarouselItemsOrdered,
	};
} 

export default Carousel_ctrl;