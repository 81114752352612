
import {
	globalStore,
	setCurrentCategory,
	setCurrentSection,
	setCurrentItem,
	setAdminCurrItem,
	setAdminIsActive,
	setAdminModeAddMod,

	setFAdmIte_ti,
	setFAdmIte_ds,
	setFAdmIte_ta,
	setFAdmIte_co,
	setFAdmIte_or,
	setFAdmIte_ip,
	setFAdmIte_ci,
	setFAdmIte_av,
	setFAdmIte_en,
	setFAdmIte_de,
} from '../../../globalstate';

import { Getter } from '../../../generaltools';

function ItemItem_ctrl(forCtrler) {


	const handleClickOnItem = () => {
		// var link = "/category/" + forCtrler.category_item.id;
		const category = Getter.getCategory(forCtrler.item.category_id, forCtrler.allCategories);

		globalStore.dispatch(setCurrentItem({item: forCtrler.item}));
		globalStore.dispatch(setCurrentCategory({category: category}));
		globalStore.dispatch(setCurrentSection({section: Getter.getSection(category.section_id, forCtrler.allSection)}));

		const routeItem = '/item/' + forCtrler.item.slug;

		forCtrler.navigate(routeItem);
	};

	const isCurrentItem = () => {		
		let idCurrItem = null;
		if (forCtrler.currentItem) {
			idCurrItem = forCtrler.currentItem.id;
		} else {
			idCurrItem = forCtrler.idItem;
		}

		if (forCtrler.item && idCurrItem) {
			if (forCtrler.item.id.toString() === idCurrItem.toString()) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	};

	const isAdminCurrentItem = () => {
		if (forCtrler.item.id === forCtrler.adminCurrentItem.id) {
			return true;
		} else {
			return false;
		}
	};

	const handleClickOnAdminItem = () => {
		globalStore.dispatch(setAdminCurrItem(forCtrler.item));
		globalStore.dispatch(setAdminIsActive("ITEM"));
		globalStore.dispatch(setAdminModeAddMod("M"));

		globalStore.dispatch(setFAdmIte_ti(forCtrler.item.title));
		globalStore.dispatch(setFAdmIte_ds(forCtrler.item.description));
		globalStore.dispatch(setFAdmIte_ta(forCtrler.item.tag));
		globalStore.dispatch(setFAdmIte_co(forCtrler.item.carousel_order));
		globalStore.dispatch(setFAdmIte_or(forCtrler.item.order));
		globalStore.dispatch(setFAdmIte_ip(forCtrler.item.path));
		globalStore.dispatch(setFAdmIte_ci(forCtrler.item.category_id));
		globalStore.dispatch(setFAdmIte_av(forCtrler.item.availble));
		globalStore.dispatch(setFAdmIte_en(forCtrler.item.enable));
		globalStore.dispatch(setFAdmIte_de(forCtrler.item.is_delete));
	};

	return {
		handleClickOnItem: handleClickOnItem,
		isCurrentItem: isCurrentItem,
		isAdminCurrentItem: isAdminCurrentItem,
		handleClickOnAdminItem: handleClickOnAdminItem,
	};
} 

export default ItemItem_ctrl;