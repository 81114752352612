
import { useSelector } from 'react-redux';

import { Getter } from '../../../../../generaltools';

import {
	globalStore,
	getFAdmAbo_ti,
	getFAdmAbo_tf,
	getFAdmAbo_co,
	getFAdmAbo_ip,
	getFAdmAbo_op,
	getFAdmAbo_na,
	setFAdmAbo_op,
	setFAdmAbo_na,
	// getFAdmAbo_en,
	// getFAdmAbo_de,
} from '../../../../../globalstate';

import {
	StyAdminPartTittleH4,
	StyAdminPartInputDiv,
	StyAdminPartInputLabel,
	StyAdminPartInputInput,
	StyAdminPartInputImg,
	StyAdminSaveInputButton,
	StyAdminPartInputTextarea,
	StyAdminPartInputTextareaLabel,
	StyAdminPartInputRadioLabel,
	StyAdminPartInputRadioInput,
	StyAdminPartErrorDiv,
	StyAdminPartErrorSpan,
	StyAdminPartInputListSelect,
} from '../../../../../style/';


function AdminAddAbout({onCLickSave, ctrler}) {

	// let aboutPara_curr = {};
	// if (ctrler.aboutPara.title
	// 	|| ctrler.aboutPara.title_force
	// 	|| ctrler.aboutPara.content
	// 	|| ctrler.aboutPara.img_path
	// 	|| ctrler.aboutPara.order_place
	// 	|| ctrler.aboutPara.enable
	// 	|| ctrler.aboutPara.is_delete) {
	// 	aboutPara_curr = ctrler.aboutPara;
	// } else {
	// 	aboutPara_curr = {};
	// }

	const aboutPara_ti = useSelector(getFAdmAbo_ti(globalStore.getState())) || "";
	const aboutPara_tf = useSelector(getFAdmAbo_tf(globalStore.getState())) || 0;
	const aboutPara_co = useSelector(getFAdmAbo_co(globalStore.getState())) || "";
	const aboutPara_ip = useSelector(getFAdmAbo_ip(globalStore.getState())) || "";
	const aboutPara_op = useSelector(getFAdmAbo_op(globalStore.getState())) || 0;
	const aboutPara_na = useSelector(getFAdmAbo_na(globalStore.getState())) || 0;
	// const aboutPara_en = useSelector(getFAdmAbo_en(globalStore.getState())) || true;
	// const aboutPara_de = useSelector(getFAdmAbo_de(globalStore.getState())) || false;
	
	if (aboutPara_op === 0) {
		globalStore.dispatch(setFAdmAbo_op(ctrler.maxOrdreP1));
	}
	if (aboutPara_na === 0) {
		globalStore.dispatch(setFAdmAbo_na(ctrler.maxNoArticleP1));
	}

	const aboutOrders = ctrler.putAboutOrder();
	const aboutNoArticles = ctrler.putAboutNoArticle();

	return (
		<div>
			<StyAdminPartTittleH4>Ajouter un paragraphe</StyAdminPartTittleH4>
			<form>
				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='no_article_about'>Article :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{ctrler.errorEpty_na ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputListSelect id='no_article_about' name='no_article_about' value={aboutPara_na} onChange={ctrler.handleChangeAboutPara_na}>
						{
							aboutNoArticles.map((aboutNoArticle, lkId) => {
								lkId++;
								return (<option value={aboutNoArticle} key={lkId}>{aboutNoArticle}</option>)
							})
						}
					</StyAdminPartInputListSelect>
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='title_about'>Titre :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{ctrler.errorEpty_ti ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputInput type='text' id='title_about' name='title_about' value={aboutPara_ti} onChange={ctrler.handleChangeAboutPara_ti} />
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='title_force_about'>Force du titre :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{ctrler.errorEpty_tf ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputListSelect id='title_force_about' name='title_force_about' value={aboutPara_tf} onChange={ctrler.handleChangeAboutPara_tf}>
						<option value={0} default>Choisir...</option>
						<option value={1}>1</option>
						<option value={2}>2</option>
						<option value={3}>3</option>
						<option value={4}>4</option>
						<option value={5}>5</option>
						<option value={6}>6</option>
					</StyAdminPartInputListSelect>
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputTextareaLabel htmlFor='content_about'>Contenu :</StyAdminPartInputTextareaLabel>
					<StyAdminPartInputTextarea id='content_about' name='content_about' value={aboutPara_co} onChange={ctrler.handleChangeAboutPara_co}>
					</StyAdminPartInputTextarea>
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='img_path_about'>Image :</StyAdminPartInputLabel>
					<StyAdminPartInputInput type='file' id='img_path_about' name='img_path_about' onChange={ctrler.handleChangeAboutPara_ip} />
					<StyAdminPartInputImg src={aboutPara_ip.service_url || Getter.getImgPath(aboutPara_ip)} alt='imgUploaded' />
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='order_place_about'>Ordre :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{ctrler.errorEpty_op ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputListSelect id='order_place_about' name='order_place_about' value={aboutPara_op} onChange={ctrler.handleChangeAboutPara_op}>
						{
							aboutOrders.map((aboutOrder, lkId) => {
								lkId++;
								return (<option value={aboutOrder} key={lkId}>{aboutOrder}</option>)
							})
						}
					</StyAdminPartInputListSelect>
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel>Activer :</StyAdminPartInputLabel>
					<StyAdminPartInputRadioLabel htmlFor='enable_y_about'>Oui</StyAdminPartInputRadioLabel>
					<StyAdminPartInputRadioInput type='radio' id='enable_y_about' name='enable_about' value='Y' defaultChecked onClick={ctrler.handleChangeAboutPara_en} />
					<StyAdminPartInputRadioLabel htmlFor='enable_n_about'>Non</StyAdminPartInputRadioLabel>
					<StyAdminPartInputRadioInput type='radio' id='enable_n_about' name='enable_about' value='N' onClick={ctrler.handleChangeAboutPara_en} />
				</StyAdminPartInputDiv>

				<StyAdminPartErrorDiv>
					{ctrler.errorEpty_all ? 'Veuillez remplir les champs obligatoires' : ''}
				</StyAdminPartErrorDiv>
				<StyAdminPartInputDiv>
					<StyAdminSaveInputButton onClick={onCLickSave}>Sauvegarder</StyAdminSaveInputButton>
				</StyAdminPartInputDiv>
			</form>
		</div>
	);
} 

export default AdminAddAbout;

// <StyAdminPartInputInput type='text' id='title_force_about' name='title_force_about' value={aboutPara_tf} onChange={ctrler.handleChangeAboutPara_tf} />
// <StyAdminPartInputInput type='text' id='order_place_about' name='order_place_about' value={aboutPara_op} onChange={ctrler.handleChangeAboutPara_op} />
					