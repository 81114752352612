
import styled from 'styled-components';


const StyFooterPlaceDiv = styled.div`
	margin-top: 10px;

	padding-top: 10px;
	padding-bottom: 10px;

	background-color: rgb(10, 10, 10);

	width: 100%;
	float: left;
`

const StyFooterTitleH4 = styled.h4`
	font-size: 22px;
	font-weight: 900;

	margin-bottom: 15px;
	margin-top: 0px;
`

const StyFooterLinkPlaceDiv = styled.nav`
	display: inline-block;

	width: 30%;
	margin-left: 5px;

	border-right: solid 1px rgb(0, 158, 199);
	border-radius: 10px 10px 10px 10px;
`

const StyFooterLinkLinkA = styled.a`
	display: block;
	margin-left: 15px;
	margin-bottom: 5px;

	text-decoration: none;
	color: rgb(215, 216, 221);

	font-size: 18px;
	width: 110px;
`

const StyFooterLinkPlanLinkA = styled.a`
        display: block;
	margin-top: 10px;
        margin-left: 15px;
        margin-bottom: 5px;

        text-decoration: none;
        color: rgb(215, 216, 221);

        font-size: 12px;
	font-style: italic;
        width: 110px;
`

const StyFooterLinkLinkImg = styled.img`
	margin-right: 5px;

	width: 15px;
	height: 15px;
`

const StyFooterAddressPlaceDiv = styled.div`
	width: 65%;
	display: inline-block;
	margin-left: 10px;
	padding-top: 2px;
	vertical-align: top;
`

const StyFooterAddressTextP = styled.p` 
	margin-bottom: -12px;
	margin-left: 10px;
`

const StyFooterAddressTextCitySpan = styled.span`
	margin-right: 10px;
`

const StyFooterAddressTextStree1Span = styled.span`
	margin-right: 10px;
`

const StyFooterAddressTextStree2Span = styled.span`
	margin-right: 0px;
`

const StyFooterAdresseAdressePlaceDiv = styled.nav`
	margin-top: -13px;
`

const StyFooterAdresseDetailPlaceDiv = styled.div`
	margin-top: 22px;
`

const StyFooterAddressTextLittleSpan = styled.span`
	color: rgb(215, 216, 221);
	font-style: italic;
	font-size: 15px;
`

const StyAddressTextLinkA = styled.a`
	color: rgb(215, 216, 221);
`

const StyFooterAddressMapImg = styled.img`
	width: 20px;
	height: 20px;
	margin-right: 5px;
`

const StyFooterAddressMapA = styled.a`
	&:hover {
		cursor: pointer;
	}
`

export {
	StyFooterPlaceDiv,
	StyFooterTitleH4,
	StyFooterLinkPlaceDiv,
	StyFooterLinkLinkA,
	StyFooterLinkPlanLinkA,
	StyFooterLinkLinkImg,
	StyFooterAddressPlaceDiv,
	StyFooterAddressTextP,
	StyFooterAddressTextCitySpan,
	StyFooterAddressTextStree1Span,
	StyFooterAddressTextStree2Span,
	StyFooterAdresseAdressePlaceDiv,
	StyFooterAdresseDetailPlaceDiv,
	StyFooterAddressTextLittleSpan,
	StyAddressTextLinkA,
	StyFooterAddressMapImg,
	StyFooterAddressMapA,
};
