
import { Getter } from '../../../../generaltools';

import {
	// StyAdminPartImgItemDiv,
	StyAdminPartItemImgItemDiv,
	StyAdminPartImgItemImg,
} from '../../../../style/';

function ItemItemAdmin({location, item, onClickItem}) {

	return (
		<StyAdminPartItemImgItemDiv onClick={onClickItem}>
			<StyAdminPartImgItemImg src={Getter.getImgPath(item.path)} alt={item.title} />
		</StyAdminPartItemImgItemDiv>
	);
} 

export default ItemItemAdmin;