
import { useState } from 'react';

import {
	StyAdminPartTittleH4,
	StyAdminPartInputDiv,
	StyAdminPartInputLabel,
	StyAdminPartInputInput,
	StyAdminSaveInputButton,
} from '../../../../style/';

import { AdminConnect_ctrl } from '../../../../controler/';

function AdminConnect() {

	const [adm_login, setAdm_login] = useState("");
	const [adm_pwd, setAdm_pwd] = useState("");

	const forCtrler = {
		adm_login: adm_login,
		adm_pwd: adm_pwd,
		setAdm_login: setAdm_login,
		setAdm_pwd: setAdm_pwd,
	};
	const ctrler = AdminConnect_ctrl(forCtrler);

	return (
		<div>
			<StyAdminPartTittleH4>Administration</StyAdminPartTittleH4>
			<form>
				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor="adm_login">Login :</StyAdminPartInputLabel>
					<StyAdminPartInputInput type="text" name="adm_login" id="adm_login" onChange={ctrler.handleChangeAdmLogin} value={adm_login} />
				</StyAdminPartInputDiv>
				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor="adm_pwd">Mot de passe :</StyAdminPartInputLabel>
					<StyAdminPartInputInput type="password" name="adm_pwd" id="adm_pwd" onChange={ctrler.handleChangeAdmPwd} value={adm_pwd} />
				</StyAdminPartInputDiv>
				<StyAdminPartInputDiv>
					<StyAdminSaveInputButton onClick={ctrler.handleSubmitAdmConnect}>Connexion</StyAdminSaveInputButton>
				</StyAdminPartInputDiv>
			</form>
		</div>
	);
} 

export default AdminConnect;