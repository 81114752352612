

import {
	Section,
} from '../';

import {
	StyMenuLeftPlaceDiv,
	StyMenuLeftLinkAboutDiv,
} from '../../../style/';

function MenuLeft({location, elementsTree, aboutAll, currentAbout, onClickAboutTitle}) {
	return (
		<StyMenuLeftPlaceDiv>
			{
				location === 'ABOUT' ? (
						<div>
							{
								aboutAll.length !== 0 ? (
									aboutAll.map((about, liId) => {
										if (about.title_force === 1) {
											liId++;
											let isCurrent = false;
											if (about.id === currentAbout.id) {
												isCurrent = true;
											}
											return (
												<StyMenuLeftLinkAboutDiv id={about.slug} isCurrent={isCurrent} key={liId} onClick={onClickAboutTitle} >
													- {about.title}
												</StyMenuLeftLinkAboutDiv>
											)
										} else {
											return '';
										}
									})
								) : (<div></div>)
							}
						</div>
					) : (
						<div>
							{
								elementsTree.sections.length !== 0 ? (
									elementsTree.sections.map((sectionListItem, liId) => {
										liId++;
										return (
											<div key={liId}>
												<Section
													location={'MENULEFT'}
													section={sectionListItem}
													categoryList={elementsTree.categories}
													itemList={elementsTree.items} />
											</div>
										)
									})
								) : (<div></div>)
							}
						</div>
					)
			}
		</StyMenuLeftPlaceDiv>
	);
} 

export default MenuLeft;