


import styled from 'styled-components';


const StyGeneralButtonAddButton = styled.div`
	margin-top: -15px;
	margin-left: 10px;
	margin-bottom: 10px;

	width: 150px;
	border: 1px solid rgb(200, 200, 200);

	border-radius: 15px 15px 15px 15px;
	background-color: rgb(200, 200, 200);

	text-align: center;

	font-size: 15px;
	font-weight: 900;
	color: rgb(18, 21, 25);

	&:hover {
		cursor: pointer;
		background-color: rgb(0, 158, 199);
	}

	&:active {
		border: 1px solid rgb(0, 158, 199);
	}
`

const StyGeneralPagePlaceDiv = styled.div`
	width: auto;
	margin-left: 160px;
`

const StyGeneralPageArticle = styled.article`
`

const StyGeneralPageMain = styled.main`
`

const StyGeneralPageH1 = styled.h1`
	font-size: 24px;
	font-weight: 900;
`

const StyGeneralPageHeader = styled.header`
	font-size: 14px;
	font-weight: 400;
	text-align: center;
`
	// border-bottom: 1px solid rgb(0, 158, 199);
	// border-radius: 0px 0px 10px 10px;

const StyGeneralSpaceDiv = styled.div`
	height: 40px;
	width: 10px;
`

export {
	StyGeneralButtonAddButton,
	StyGeneralPagePlaceDiv,
	StyGeneralPageArticle,
	StyGeneralPageMain,
	StyGeneralPageH1,
	StyGeneralPageHeader,
	StyGeneralSpaceDiv,
};