
import { useSelector } from 'react-redux';

import { Section_ctrl } from '../../../controler/';

import { CategoryItem } from '../';

import {
	globalStore,
	getCurrentSection,
	getAdminCurrSection,
	getAdminCurrCategory,
	// getAdminIsActive,
} from '../../../globalstate';

import {
	StySectionPlaceDiv,
	StySectionNameMainH2,
	StySectionNameMenuLeftH4,
	StySectionNameAdminH2,
	// StySectionCategoryPlaceDiv,
	StySectionBigCategoryPlaceDiv,
	StySectionBigCategoryEmptyPlaceDiv,
	StySectionLittleCategoryPlaceDiv,
	StySectionAdminPartPlaceDiv,
	StyGeneralButtonAddButton,
} from '../../../style/';


function Section({location, section, categoryList, itemList, onClickSection, onClickCategory, onClickItem, onClickAddCategory, onClickAddItem}) {

	const categoryListSection = categoryList || [];

	const currentSection = useSelector(getCurrentSection(globalStore.getState()));
	const adminCurrentSection = useSelector(getAdminCurrSection(globalStore.getState()));
	const adminCurrentCategory = useSelector(getAdminCurrCategory(globalStore.getState()));

	const forCtrler = {
		section: section,
		currentSection: currentSection,
		adminCurrentSection: adminCurrentSection,
	};
	const ctrler = Section_ctrl(forCtrler);

	const isCurrentSection = ctrler.isCurrentSection();

	return (
		<StySectionPlaceDiv>
			{ location === 'MAIN' ? (
					<div>
						<StySectionNameMainH2>{section.name}</StySectionNameMainH2>
					</div>
				) : (
					<div>
						{ location === 'MENULEFT' ? (
								<div>
									<StySectionNameMenuLeftH4 onClick={onClickSection} iscurrent={isCurrentSection.toString()}>{section.name}</StySectionNameMenuLeftH4>
								</div>
							) : (
								<div>
									<StySectionNameAdminH2 onClick={ctrler.onClickAdminSection}>{section.name}</StySectionNameAdminH2>
									{
										location === 'ADMIN' && ctrler.isAdminCurrentSection() ? (
											<div>
												<StyGeneralButtonAddButton onClick={onClickAddCategory}>Ajouter Catégorie</StyGeneralButtonAddButton>
											</div>
										) : (<div></div>)
									}
								</div>
							)
						}
					</div>
				)
			}
			{
				location === 'MENULEFT' ? (
						<div>
							{
								categoryListSection.length !== 0 ? (
									categoryListSection.map((categoryListItem, liId) => {
										liId++;
										if (categoryListItem.section_id === section.id && categoryListItem.enable === true) {
											return (
												<StySectionLittleCategoryPlaceDiv key={liId}>
													<CategoryItem
														location={location}
														category_item={categoryListItem}
														itemList={itemList}
														onClickCategory={onClickCategory}
														onClickItem={onClickItem}
														onClickAddItem={onClickAddItem} />
												</StySectionLittleCategoryPlaceDiv>												
											)
										} else { return (<div key={liId}></div>) }
									})
								) : (<div></div>)
							}
						</div>
					) : (
						<div>
							{
								location === 'ADMIN' && ctrler.isAdminCurrentSection() ? (
									<StySectionAdminPartPlaceDiv>
										{
											categoryListSection.length !== 0 ? (
												categoryListSection.map((categoryListItem, liId) => {
													liId++;
													if (categoryListItem.section_id === section.id) {
														let isCurrCateAdm = false;
														if (adminCurrentCategory.id === categoryListItem.id) {
															isCurrCateAdm = true;
														}
														return (
															<StySectionBigCategoryPlaceDiv key={liId} location={location} isCurrCateAdm={isCurrCateAdm}>
																<CategoryItem
																	location={location}
																	category_item={categoryListItem}
																	itemList={itemList}
																	onClickCategory={onClickCategory}
																	onClickItem={onClickItem}
																	onClickAddItem={onClickAddItem} />
															</StySectionBigCategoryPlaceDiv>
														)
													} else { return (<div key={liId}></div>) }
												})
											) : (<div></div>)
										}
									</StySectionAdminPartPlaceDiv>
								) : (
									<div>
										{
											location === 'MAIN' ? (
												<div>
													{
														categoryListSection.length !== 0 ? (
															categoryListSection.map((categoryListItem, liId) => {
																liId++;
																if (categoryListItem.section_id === section.id && categoryListItem.enable === true) {
																	return (
																		<StySectionBigCategoryPlaceDiv key={liId}>
																			<CategoryItem
																				location={location}
																				category_item={categoryListItem}
																				itemList={itemList}
																				onClickCategory={onClickCategory}
																				onClickItem={onClickItem}
																				onClickAddItem={onClickAddItem} />
																		</StySectionBigCategoryPlaceDiv>
																	)
																} else { return (<StySectionBigCategoryEmptyPlaceDiv key={liId}></StySectionBigCategoryEmptyPlaceDiv>) }
															})
														) : (<div></div>)
													}
												</div>
											) : (<div></div>)
										}
									</div>
								)
							}
						</div>
					)
			}
		</StySectionPlaceDiv>
	);
} 

export default Section;