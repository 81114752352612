
import styled from 'styled-components';

const StyBodyDiv = styled.div`
	background-color: rgb(18, 21, 25);
	color: rgb(215, 216, 221);
`

const StyMainContentDiv = styled.div`
	margin-top: 100px;
	padding-top: 10px;
`


export {
	StyBodyDiv,
	StyMainContentDiv,
};