
import {
	globalStore,
	setAdminPartActive,
	setAdminModeAddMod,
	setAdminIsActive,

	setAdminCurrSection,
	setAdminCurrCategory,
	setAdminCurrItem,
	setAdminCurrAbout,
	
	setFAdmInf_linkIg,
	setFAdmInf_linkFb,
	setFAdmInf_linkTt,
	setFAdmInf_linkPt,
	setFAdmInf_myEmail,
	setFAdmInf_shopName,
	setFAdmInf_shopSt1,
	setFAdmInf_shopSt2,
	setFAdmInf_shopCp,
	setFAdmInf_shopCt,
	// setFAdmInf_shopCo,
	setFAdmInf_shopPh,
	setFAdmInf_shopMa,
	setFAdmInf_shopSi,

	setFActuPara_ti,
	setFActuPara_dc,
	setFActuPara_ip,
	setFActuPara_ds,
	setFActuPara_de,
	setFActuPara_sa,
	setFActuPara_ad,
	setFActuPara_cp,
	setFActuPara_ci,

	setFAdmAbo_ti,
	setFAdmAbo_tf,
	setFAdmAbo_co,
	setFAdmAbo_ip,
	setFAdmAbo_op,
	setFAdmAbo_na,
	setFAdmAbo_en,
	setFAdmAbo_de,

	setFAdmSec_na,
	setFAdmSec_or,
	setFAdmSec_en,
	setFAdmSec_de,

	setFAdmCat_na,
	setFAdmCat_or,
	setFAdmCat_ip,
	setFAdmCat_ai,
	setFAdmCat_si,
	setFAdmCat_en,
	setFAdmCat_de,

	setFAdmIte_ti,
	setFAdmIte_ds,
	setFAdmIte_ta,
	setFAdmIte_co,
	setFAdmIte_or,
	setFAdmIte_ip,
	setFAdmIte_ci,
	setFAdmIte_av,
	setFAdmIte_en,
	setFAdmIte_de,
} from '../../../globalstate';

function Admin_ctrl(forCtrler) {


	const handleClickOnSection = (section) => {
		globalStore.dispatch(setAdminCurrSection(section));
	};

	const handleClickOnCategory = (category) => {
		globalStore.dispatch(setAdminCurrCategory(category));
	};

	const handleClickOnItem = (item) => {
		globalStore.dispatch(setAdminCurrItem(item));
	};

	const handleClickOnAddSection = () => {
		globalStore.dispatch(setFAdmSec_na(""));
		globalStore.dispatch(setFAdmSec_or(forCtrler.maxOrdreSectionP1));
		globalStore.dispatch(setFAdmSec_en(true));
		globalStore.dispatch(setFAdmSec_de(false));

		globalStore.dispatch(setAdminIsActive("SECTION"));
		globalStore.dispatch(setAdminModeAddMod("A"));
	};

	const handleClickOnAddCategory = () => {
		globalStore.dispatch(setFAdmCat_na(""));
		globalStore.dispatch(setFAdmCat_or(forCtrler.maxOrdreCategoryP1));
		globalStore.dispatch(setFAdmCat_ip(""));
		globalStore.dispatch(setFAdmCat_ai(true));
		globalStore.dispatch(setFAdmCat_si(forCtrler.adminContentSectionActive.id));
		globalStore.dispatch(setFAdmCat_en(true));
		globalStore.dispatch(setFAdmCat_de(false));

		globalStore.dispatch(setAdminIsActive("CATEGORY"));
		globalStore.dispatch(setAdminModeAddMod("A"));
	};

	const handleClickOnAddItem = () => {
		globalStore.dispatch(setFAdmIte_ti(""));
		globalStore.dispatch(setFAdmIte_ds(""));
		globalStore.dispatch(setFAdmIte_ta(""));
		globalStore.dispatch(setFAdmIte_co("0"));
		globalStore.dispatch(setFAdmIte_or(forCtrler.maxOrdreItemP1));
		globalStore.dispatch(setFAdmIte_ip(""));
		globalStore.dispatch(setFAdmIte_ci(forCtrler.adminContentCategoryActive.id));
		globalStore.dispatch(setFAdmIte_av(true));
		globalStore.dispatch(setFAdmIte_en(true));
		globalStore.dispatch(setFAdmIte_de(false));

		globalStore.dispatch(setAdminIsActive("ITEM"));
		globalStore.dispatch(setAdminModeAddMod("A"));

		forCtrler.navigate("#admManage");
	};

	const handleClickOnInfo = () => {
		globalStore.dispatch(setAdminPartActive("INFO"));

		globalStore.dispatch(setFAdmInf_linkIg(forCtrler.admInfo.link_facebook));
		globalStore.dispatch(setFAdmInf_linkFb(forCtrler.admInfo.link_instagram));
		globalStore.dispatch(setFAdmInf_linkTt(forCtrler.admInfo.link_tiktok));
		globalStore.dispatch(setFAdmInf_linkPt(forCtrler.admInfo.link_pinterest));
		globalStore.dispatch(setFAdmInf_myEmail(forCtrler.admInfo.my_email));
		globalStore.dispatch(setFAdmInf_shopName(forCtrler.admInfo.shop_name));
		globalStore.dispatch(setFAdmInf_shopSt1(forCtrler.admInfo.shop_address_street1));
		globalStore.dispatch(setFAdmInf_shopSt2(forCtrler.admInfo.shop_address_street2));
		globalStore.dispatch(setFAdmInf_shopCp(forCtrler.admInfo.shop_address_cp));
		globalStore.dispatch(setFAdmInf_shopCt(forCtrler.admInfo.shop_address_city));
		globalStore.dispatch(setFAdmInf_shopPh(forCtrler.admInfo.shop_address_tel));
		globalStore.dispatch(setFAdmInf_shopMa(forCtrler.admInfo.shop_address_email));
		globalStore.dispatch(setFAdmInf_shopSi(forCtrler.admInfo.shop_link));
	};

	const handleClickOnActu = () => {
		globalStore.dispatch(setAdminPartActive("ACTU"));
		
		globalStore.dispatch(setFActuPara_ti(forCtrler.admActu.title));
		globalStore.dispatch(setFActuPara_dc(forCtrler.admActu.descr));
		globalStore.dispatch(setFActuPara_ip(forCtrler.admActu.img_path));
		globalStore.dispatch(setFActuPara_ds(forCtrler.admActu.dt_start));
		globalStore.dispatch(setFActuPara_de(forCtrler.admActu.dt_end));
		globalStore.dispatch(setFActuPara_sa(forCtrler.admActu.shop_addr));
		globalStore.dispatch(setFActuPara_ad(forCtrler.admActu.loc_addr));
		globalStore.dispatch(setFActuPara_cp(forCtrler.admActu.loc_cp));
		globalStore.dispatch(setFActuPara_ci(forCtrler.admActu.loc_city));
	};

	const handleClickOnAboutText = (aboutPara) => {
		globalStore.dispatch(setAdminCurrAbout(aboutPara));
	};

	const handleClickOnAddAboutText = () => {
		globalStore.dispatch(setFAdmAbo_ti(""));
		globalStore.dispatch(setFAdmAbo_tf("0"));
		globalStore.dispatch(setFAdmAbo_co(""));
		globalStore.dispatch(setFAdmAbo_ip(""));
		globalStore.dispatch(setFAdmAbo_op(forCtrler.maxOrdreAboutP1));
		globalStore.dispatch(setFAdmAbo_na(forCtrler.maxNoArticleAboutP1));
		globalStore.dispatch(setFAdmAbo_en(true));
		globalStore.dispatch(setFAdmAbo_de(false));

		globalStore.dispatch(setAdminModeAddMod("A"));
	};

	const handleClickOnContent = () => {
		globalStore.dispatch(setAdminPartActive("CONTENT"));
	};

	const handleClickOnAbout = () => {
		globalStore.dispatch(setAdminModeAddMod("A"));
		globalStore.dispatch(setAdminPartActive("ABOUT"));
	};
	

	return {
		handleClickOnSection: handleClickOnSection,
		handleClickOnCategory: handleClickOnCategory,
		handleClickOnItem: handleClickOnItem,
		handleClickOnAddSection: handleClickOnAddSection,
		handleClickOnAddCategory: handleClickOnAddCategory,
		handleClickOnAddItem: handleClickOnAddItem,
		handleClickOnInfo: handleClickOnInfo,
		handleClickOnAboutText: handleClickOnAboutText,
		handleClickOnAddAboutText: handleClickOnAddAboutText,
		handleClickOnContent: handleClickOnContent,
		handleClickOnAbout: handleClickOnAbout,
		handleClickOnActu: handleClickOnActu,
	};
} 

export default Admin_ctrl;