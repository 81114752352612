
import { defaultQueryFn } from '../../../../queryapi';

import {
	globalStore,
	setAdmConnected,
} from '../../../../globalstate';

function AdminConnect_ctrl(forCtrler) {

	const handleSubmitAdmConnect = async (event) => {
		event.preventDefault();

		await defaultQueryFn({
			query: "login",
			typeReq: "POST",
			args: {
				user: {
					email: forCtrler.adm_login,
					password: forCtrler.adm_pwd,
				}
			},
			enable: true,
			special: { typePost: "SIGNIN" }
		})
			.then( async (result) => {
				if (result !== null && result.data !== null) {
					if (result.data.data.id === 1 || result.data.data.id === 2) {
						globalStore.dispatch(setAdmConnected(true));
					}
				}
				return result;
			})
			.catch((error) => {
				return error;
			})
	};

	const handleChangeAdmLogin = (event) => {
		forCtrler.setAdm_login(event.target.value);
	};

	const handleChangeAdmPwd = (event) => {
		forCtrler.setAdm_pwd(event.target.value);
	};


	return {
		handleChangeAdmLogin: handleChangeAdmLogin,
		handleChangeAdmPwd: handleChangeAdmPwd,
		handleSubmitAdmConnect: handleSubmitAdmConnect,
	};
} 

export default AdminConnect_ctrl;