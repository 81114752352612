
import styled from 'styled-components';


const StyHeaderPlaceDiv = styled.div`
	position: fixed;
	z-index: 90;

	height: 100px;
	width: 100%;
	top: 0px;

	display: flex;
	align-items: center;

	background-color: rgb(10, 10, 10);
`

const StyLogoPlaceDiv = styled.nav`
	margin-left: 10px;
`

const StyLogoImgImg = styled.img`
	width: 80px;
	height: 80px;

	border: 2px solid rgb(0, 158, 199);
	border-radius: 40px 40px 40px 40px;

	&:hover {
		cursor: pointer;
	}
`

const StyMenuHeaderPlaceDiv = styled.nav`
	width: 80%;
	text-align: center;
`

const StyMenuHeaderItemSpan = styled.span`
	margin-right: 20px;

	font-size: 22px;
	font-weight: 900;
	text-decoration: underline;

	&:hover {
		color: rgb(0, 158, 199);
		cursor: pointer;
	}
`

	// text-decoration: underline;
const StyMenuHeaderItemImpSpan = styled.span`
	margin-right: 20px;

	padding-right: 5px;
	padding-left: 5px;

	border-left: 2px solid rgb(0, 158, 199);
	border-right: 2px solid rgb(0, 158, 199);
	border-radius: 5px 5px 5px 5px;

	font-size: 22px;
	font-weight: 900;

	color: rgb(0, 158, 199);

	&:hover {
		color: rgb(215, 216, 221);
		border-left: 2px solid rgb(215, 216, 221);
		border-right: 2px solid rgb(215, 216, 221);
		cursor: pointer;
	}
`


export {
	StyHeaderPlaceDiv,
	StyLogoPlaceDiv,
	StyLogoImgImg,
	StyMenuHeaderPlaceDiv,
	StyMenuHeaderItemSpan,
	StyMenuHeaderItemImpSpan,
};
