
import { defaultQueryFn } from '../../../../queryapi';

import {
	globalStore,
	setFAdmSec_na,
	setFAdmSec_or,
	setFAdmSec_en,
	setFAdmSec_de,
	setAllSection,
} from '../../../../globalstate';

function AdminAddManageSection_ctrl(forCtrler) {

	const checkErrorGeneral = (fromBtSave) => {
		let errorAll = false;

		if (forCtrler.errorEpty_na === true || forCtrler.section_na === "") {
			if (forCtrler.section_na === "" && fromBtSave) {
				forCtrler.setErrorEpty_na(true);
			}
			errorAll = true;
		}
		
		if (forCtrler.errorEpty_or === true || forCtrler.section_or === "0") {
			if (forCtrler.section_or === "0" && fromBtSave) {
				forCtrler.setErrorEpty_or(true);
			}
			errorAll = true;
		}

		forCtrler.setErrorEpty_all(errorAll);
		forCtrler.errorEpty_all = errorAll;
	};

	const handleSaveSectionAdd = async (event) => {
		event.preventDefault();

		checkErrorGeneral(true);

		if (forCtrler.errorEpty_all === false) {
			await defaultQueryFn({
				query: "sections",
				typeReq: "POST",
				args: {
					name: forCtrler.section_na,
					order: forCtrler.section_or,
					enable: forCtrler.section_en,
				},
				enable: true,
				special: null
			})
				.then( async (result) => {
					globalStore.dispatch(setAllSection(
						result && result.data && result.data.result ? result.data.result : null
					));

					return result;
				})
				.catch((error) => {
					return error;
				})
		}
	};

	const handleSaveSectionMod = async (event) => {
		event.preventDefault();

		checkErrorGeneral(true);

		if (forCtrler.errorEpty_all === false) {
			const query = "sections/" + forCtrler.section_old.id;

			if (forCtrler.section_de === true) {
				await defaultQueryFn({
					query: query,
					typeReq: "DELETE",
					args: {},
					enable: true,
					special: null
				})
					.then( async (result) => {
						globalStore.dispatch(setAllSection(
							result && result.data && result.data.result ? result.data.result : null
						));

						return result;
					})
					.catch((error) => {
						return error;
					})
			} else {
				await defaultQueryFn({
					query: query,
					typeReq: "PUT",
					args: {
						name: forCtrler.section_na,
						order: forCtrler.section_or,
						enable: forCtrler.section_en,
					},
					enable: true,
					special: null
				})
					.then( async (result) => {
						globalStore.dispatch(setAllSection(
							result && result.data && result.data.result ? result.data.result : null
						));

						return result;
					})
					.catch((error) => {
						return error;
					})
			}
		}
	};

	const handleChangeSection_na = (event) => {
		// forCtrler.stateAddManSection.setSectionNew_na(event.target.value);
		if (event.target.value === "") {
			forCtrler.setErrorEpty_na(true);
		} else {
			forCtrler.setErrorEpty_na(false);
		}
		checkErrorGeneral(false);
		globalStore.dispatch(setFAdmSec_na(event.target.value));
	};

	const handleChangeSection_or = (event) => {
		// forCtrler.stateAddManSection.setSectionNew_or(event.target.value);
		if (event.target.value === "0") {
			forCtrler.setErrorEpty_or(true);
		} else {
			forCtrler.setErrorEpty_or(false);
		}
		checkErrorGeneral(false);
		globalStore.dispatch(setFAdmSec_or(event.target.value));
	};

	const handleChangeSection_en = (event) => {
		if (event.target.value === 'N') {
			// forCtrler.stateAddManSection.setSectionNew_en(false);
			globalStore.dispatch(setFAdmSec_en(false));
		} else {
			// forCtrler.stateAddManSection.setSectionNew_en(true);
			globalStore.dispatch(setFAdmSec_en(true));
		}
	};

	const handleChangeSection_de = (event) => {
		// forCtrler.stateAddManSection.setSectionNew_de(event.target.checked);
		if (event.target.checked === true) {
			alert("Attention ! Vous venez de cocher la suppression, la sauvegarde supprimera cette section ainsi que toutes ses catégories et tous ses postes");
		}
		globalStore.dispatch(setFAdmSec_de(event.target.checked));
	};

	const putSectionOrder = () => {
		let result = [];
		for (let i = 1; i <= forCtrler.maxOrdreP1; i++) {
			result.push(i);
		}
		return result;
	};

	// const section = {
	// 	name: forCtrler.stateAddManSection.sectionNew_na,
	// 	order: forCtrler.stateAddManSection.sectionNew_or,
	// 	enable: forCtrler.stateAddManSection.sectionNew_en,
	// 	is_delete: forCtrler.stateAddManSection.sectionNew_de,
	// };	

	return {
		handleSaveSectionAdd: handleSaveSectionAdd,
		handleSaveSectionMod: handleSaveSectionMod,
		handleChangeSection_na: handleChangeSection_na,
		handleChangeSection_or: handleChangeSection_or,
		handleChangeSection_en: handleChangeSection_en,
		handleChangeSection_de: handleChangeSection_de,
		errorEpty_all: forCtrler.errorEpty_all,
		errorEpty_na: forCtrler.errorEpty_na,
		errorEpty_or: forCtrler.errorEpty_or,
		maxOrdreP1: forCtrler.maxOrdreP1,
		putSectionOrder: putSectionOrder,
		// section: section,
	};
} 

export default AdminAddManageSection_ctrl;