
import { Getter } from '../../../../generaltools';

import {
	StyItemItemLittleContentDiv,
	StyItemItemLittleImgImg,
} from '../../../../style/';

function ItemItemLittle({location, item, onClick, isCurrent}) {

	return (
		<StyItemItemLittleContentDiv onClick={onClick}>
			<StyItemItemLittleImgImg src={Getter.getImgPath(item.path)} alt={item.title} iscurrent={isCurrent.toString()} />
		</StyItemItemLittleContentDiv>
	);
} 

export default ItemItemLittle;