
import axios from 'axios'
import { DirectUpload } from 'activestorage';

import { BASE_URL } from '../globalvar';

import {
	globalStore,
	setFAdmAbo_ip,
	setFAdmCat_ip,
	setFAdmIte_ip,
	setFActuPara_ip,
} from '../globalstate';



var axiosAutho = "";

var axiosHeaders = {
	Authorization: axiosAutho
};

const defaultQueryFn = async ({ query, typeReq, args, enable, special }) => {

	const defaultReturn = {
		error: 0,
		retult: {},
		statut: "ok"
	}

	if (enable === true) {

		switch (typeReq) {
			case "GET":
				return await axios.get(BASE_URL.concat(query), {
					headers: { Authorization: axiosHeaders.Authorization },
					params: args
				})
					.then((result) => {
						return result;
					})
					.catch((error) => {
						return error;
					})

			case "POST":
				return await axios.post(BASE_URL.concat(query), args, { headers: { authorization: axiosHeaders.Authorization } })
					.then((result) => {
						if (special
						 && (special.typePost === "SIGNUP"
						  || special.typePost === "SIGNIN")) {
								axiosHeaders = {
									...axiosHeaders,
									Authorization: result.headers.get("Authorization")
								};
						}
						return result;
					})
					.catch((error) => {
						return error;
					})

			case "PUT":
				return await axios.put(BASE_URL.concat(query), args, { headers: { authorization: axiosHeaders.Authorization } })
					.then((result) => {
						return result;
					})
					.catch((error) => {
						return error;
					})

			case "DELETE":
				return await axios.delete(BASE_URL.concat(query), args, { headers: { authorization: axiosHeaders.Authorization } })
					.then((result) => {
						if (special
						 && (special.typeDelete === "LOGOUT")) {
								axiosHeaders = {
									...axiosHeaders,
									Authorization: ""
								};
						}
						return result;
					})
					.catch((error) => {
						return error;
					})

			case "FILE":
				const upload_file = new DirectUpload(args, BASE_URL.concat("rails/active_storage/direct_uploads"), {
								directUploadWillCreateBlobWithXHR: (xhr) => {
									// This will decorate the requests with the access token header so you won't get a 401
									xhr.setRequestHeader("Authorization", axiosHeaders.Authorization)
								}
							});
				return await upload_file.create((error, blob) => {
					if (error) {
						return error;
					} else {
						switch(special.typeItem) {
							case 'ABOUT':
								globalStore.dispatch(setFAdmAbo_ip(blob));
							break;
							case 'CATEGORY':
								globalStore.dispatch(setFAdmCat_ip(blob));
							break;
							case 'ITEM':
								globalStore.dispatch(setFAdmIte_ip(blob));
							break;
							case 'ACTU':
								globalStore.dispatch(setFActuPara_ip(blob));
							break;
							
							default:
							break;
						}
						return blob;
					}
				});

			default:
				return defaultReturn;
		}
	}
};


export {
	defaultQueryFn
};
