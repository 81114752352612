
import styled from 'styled-components';


const StyItemItemPlaceDiv = styled.div`
	margin-left: 5px;
	margin-right: 5px;
	margin-bottom: 5px;
`

const StyItemItemDiv = styled.div`
	width: 100%;
	height: 100%;
`

const StyItemItemLittleContentDiv = styled.div`
	width: 20px;
	height: 20px;

	&:hover {
		cursor: pointer;
	}
`

const StyItemItemLittleImgImg = styled.img`
	width: 20px;
	height: 20px;
	border: ${props => props.iscurrent === "true" ? '1px solid rgb(0, 158, 199)' : '1px solid rgb(215, 216, 221)'};
	border-radius: 10px 10px 10px 10px;
`

const StyItemItemBigPlaceDiv = styled.div`
	width: 280px;
	height: 280px;
	margin-right: 20px;
	margin-bottom: 60px;

	&:hover {
		cursor: pointer;
	}
`

const StyItemItemBigContentFigure = styled.figure`
	margin-top: 0px;
	margin-left: 0px;
	width: 280px;
	height: 280px;
`

const StyItemItemBigContentImg = styled.img`
	border: 2px solid rgb(0, 158, 199);
	border-radius: 140px 140px 140px 140px;
	width: 280px;
	height: 280px;
`

const StyItemItemBigContentFigcaption = styled.figcaption`
	margin-top: 5px;
	font-size: 15px;
	font-weight: 400;
	font-style: italic;
	text-align: center;
`


export {
	StyItemItemPlaceDiv,
	StyItemItemDiv,
	StyItemItemLittleContentDiv,
	StyItemItemLittleImgImg,
	StyItemItemBigPlaceDiv,
	StyItemItemBigContentFigure,
	StyItemItemBigContentImg,
	StyItemItemBigContentFigcaption,
};