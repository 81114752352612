
import { Getter } from '../../../../generaltools';

import {
	StyItemItemBigPlaceDiv,
	StyItemItemBigContentFigure,
	StyItemItemBigContentImg,
	StyItemItemBigContentFigcaption,
} from '../../../../style/';

function ItemItemBig({location, item, onClick}) {

	return (
		<StyItemItemBigPlaceDiv onClick={onClick}>
			<StyItemItemBigContentFigure>
				<StyItemItemBigContentImg src={Getter.getImgPath(item.path)} alt={item.title} />
				<StyItemItemBigContentFigcaption>{item.title}</StyItemItemBigContentFigcaption>
			</StyItemItemBigContentFigure>
		</StyItemItemBigPlaceDiv>
	);
} 

export default ItemItemBig;