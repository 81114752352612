
import { useSelector } from 'react-redux';

import {
	globalStore,
	getAdminInfos,
} from '../../../globalstate';

import {
	Carousel,
	Section,
	HelmetComp,
 } from '../../compoment/';

import { Getter } from '../../../generaltools';

import {
	StyWelcomePlaceDiv,
	StyWelcomeSectionPlaceDiv,
	StyGeneralPageMain,
	StyGeneralPageH1,
	StyGeneralPageHeader,
 } from '../../../style/';

function Welcome({elementsTree}) {
	const admin_info = useSelector(getAdminInfos(globalStore.getState())) || {shop_address_city: '-'};

	const headerPage = Getter.getHeaderPage({pageName: 'WELCOME', info: admin_info});
// 	let headerPage = {};
// 	Getter.getHeaderPage({pageName: 'WELCOME', info: admin_info})
// 		.then(headerPageGetter => {
// console.log("Welcome => Getter.getHeaderPage.then => headerPageGetter=", headerPageGetter)
// 			headerPage = headerPageGetter;
// 		})

	// console.log("Welcome => headerPage=", headerPage)

	return (
		<StyWelcomePlaceDiv>
			<HelmetComp
				title = {headerPage.title}
				description = {headerPage.descr}
				image = {headerPage.img} />
			<StyGeneralPageHeader>
				<Carousel carouselItems={elementsTree.items} />
				<StyGeneralPageH1>{headerPage.title}</StyGeneralPageH1>
				<p>{headerPage.descr}</p>
			</StyGeneralPageHeader>
			<StyGeneralPageMain>
			{
				elementsTree.sections.length !== 0 ? (
					elementsTree.sections.map((sectionListItem, liId) => {
						liId++;
						if (sectionListItem.enable === true) {
							return (
								<StyWelcomeSectionPlaceDiv key={liId}>
									<Section
										location={'MAIN'}
										section={sectionListItem}
										categoryList={elementsTree.categories}
										itemList={elementsTree.items} />
								</StyWelcomeSectionPlaceDiv>
							)
						} else {
							return (<div key={liId}></div>)
						}
					})
				) : (<div></div>)
			}
			</StyGeneralPageMain>
		</StyWelcomePlaceDiv>
	);
} 

export default Welcome;
