
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
	globalStore,
	getCurrentCategory,
	getAllSection,
	getAdminCurrCategory,
} from '../../../globalstate';

import { CategoryItem_ctrl } from '../../../controler/';

import { StyCatagoryItemPlaceDiv } from '../../../style';

import {
	CategoryItemLittle,
	CategoryItemBig,
	CategoryItemAdmin,
} from './';

function CategoryItem({location, category_item, itemList, onClickCategory, onClickItem, onClickAddItem}) {

	const navigate = useNavigate();
	const {id} = useParams();
	const currentCategory = useSelector(getCurrentCategory(globalStore.getState()));
	const allSection = useSelector(getAllSection(globalStore.getState()));
	const adminCurrentCategory = useSelector(getAdminCurrCategory(globalStore.getState()));

	const forCtrler = {
		category_item: category_item,
		currentCategory: currentCategory,
		navigate: navigate,
		location: location,
		allSection: allSection,
		adminCurrentCategory: adminCurrentCategory,
		idCate: id,
	};
	const ctrler = CategoryItem_ctrl(forCtrler);

	const isCurrentCategory = ctrler.isCurrentCategory();

	return (
		<StyCatagoryItemPlaceDiv>
			{ location === 'MAIN' ? (
					<div>
						<CategoryItemBig
							location={location}
							category_item={category_item || currentCategory}
							onClick={ctrler.handleClickOnCategoryitem}
							setCategoryName={ctrler.setCategoryName} />
					</div>
				) : (
					<div>
						{ location === 'MENULEFT' ? (
								<div>
									<CategoryItemLittle
										location={location}
										category_item={category_item || currentCategory}
										itemList={itemList}
										onClick={ctrler.handleClickOnCategoryitem}
										setCategoryName={ctrler.setCategoryName}
										isCurrent={isCurrentCategory} />
								</div>
							) : (
								<div>
									<CategoryItemAdmin
										location={location}
										category_item={category_item || currentCategory}
										itemList={itemList}
										onClickCategory={ctrler.onClickAdminCategory}
										onClickItem={onClickItem}
										onClickAddItem={onClickAddItem}
										setCategoryName={ctrler.setCategoryName}
										isAdminCurrentCategory={ctrler.isAdminCurrentCategory} />
								</div>
							)
						}
					</div>
				)
			}
		</StyCatagoryItemPlaceDiv>
	);
}

export default CategoryItem;