
import { useSelector } from 'react-redux';

import { Getter } from '../../../../../generaltools';

import {
	globalStore,
	getFAdmIte_ti,
	getFAdmIte_ds,
	getFAdmIte_ta,
	getFAdmIte_co,
	getFAdmIte_or,
	getFAdmIte_ip,
	getFAdmIte_ci,
	// getFAdmIte_av,
	// getFAdmIte_en,
	// getFAdmIte_de,
	setFAdmIte_or,
	setFAdmIte_co,
} from '../../../../../globalstate';

import {
	StyAdminPartTittleH4,
	StyAdminPartInputDiv,
	StyAdminPartInputLabel,
	StyAdminPartInputInput,
	StyAdminPartInputImg,
	StyAdminSaveInputButton,
	StyAdminPartInputTextarea,
	StyAdminPartInputTextareaLabel,
	StyAdminPartInputRadioLabel,
	StyAdminPartInputRadioInput,
	StyAdminPartInputListSelect,
	StyAdminPartErrorDiv,
	StyAdminPartErrorSpan,
} from '../../../../../style/';

function AdminAddItem({onCLickSave, ctrler, sectionList, categoryList}) {

	const item_ti = useSelector(getFAdmIte_ti(globalStore.getState())) || "";
	const item_ds = useSelector(getFAdmIte_ds(globalStore.getState())) || "";
	const item_ta = useSelector(getFAdmIte_ta(globalStore.getState())) || "";
	const item_co = useSelector(getFAdmIte_co(globalStore.getState())) || 0;
	const item_or = useSelector(getFAdmIte_or(globalStore.getState())) || 0;
	const item_ip = useSelector(getFAdmIte_ip(globalStore.getState())) || "";
	const item_ci = useSelector(getFAdmIte_ci(globalStore.getState())) || 0;
	// const item_av = useSelector(getFAdmIte_av(globalStore.getState())) || true;
	// const item_en = useSelector(getFAdmIte_en(globalStore.getState())) || true;
	// const item_de = useSelector(getFAdmIte_de(globalStore.getState())) || false;

	if (item_or === 0) {
		globalStore.dispatch(setFAdmIte_or(ctrler.maxOrdreP1));
	}
	if (item_co === 0) {
		globalStore.dispatch(setFAdmIte_co(ctrler.maxCarouOrdreP1));
	}

	const itemOrders = ctrler.putItemOrder();
	const itemCarouOrders = ctrler.putItemCarouOrder();

	return (
		<div>
			<StyAdminPartTittleH4>Ajouter un poste</StyAdminPartTittleH4>
			<form>
				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='title_item'>Titre :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{ctrler.errorEpty_ti ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputInput type='text' id='title_item' name='title_item' value={item_ti} onChange={ctrler.handleChangeAItem_ti} />
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputTextareaLabel htmlFor='description_item'>Description :</StyAdminPartInputTextareaLabel>
					<StyAdminPartInputTextarea id='description_item' name='description_item' value={item_ds} onChange={ctrler.handleChangeAItem_ds}>
					</StyAdminPartInputTextarea>
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='tag_item'>Tag :</StyAdminPartInputLabel>
					<StyAdminPartInputInput type='text' id='tag_item' name='tag_item' value={item_ta} onChange={ctrler.handleChangeAItem_ta} />
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='path_item'>Image :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{ctrler.errorEpty_ip ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputInput type='file' id='path_item' name='path_item' onChange={ctrler.handleChangeAItem_ip} />
					<StyAdminPartInputImg src={item_ip.service_url || Getter.getImgPath(item_ip)} alt='imgUploaded' />
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='carousel_order_item'>Ordre carrousel :</StyAdminPartInputLabel>
					<StyAdminPartInputListSelect id='carousel_order_item' name='carousel_order_item' value={item_co} onChange={ctrler.handleChangeAItem_co}>
						<option value={0}>Choisir...</option>
						{
							itemCarouOrders.map((itemCarouOrder, lkId) => {
								lkId++;
								return (<option value={itemCarouOrder} key={lkId}>{itemCarouOrder}</option>)
							})
						}
					</StyAdminPartInputListSelect>
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='order_item'>Ordre :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{ctrler.errorEpty_or ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputListSelect id='order_item' name='order_item' value={item_or} onChange={ctrler.handleChangeAItem_or}>
						{
							itemOrders.map((itemOrder, lkId) => {
								lkId++;
								return (<option value={itemOrder} key={lkId}>{itemOrder}</option>)
							})
						}
					</StyAdminPartInputListSelect>
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel htmlFor='category_id_item'>Category :</StyAdminPartInputLabel>
					<StyAdminPartErrorSpan>{ctrler.errorEpty_ci ? '*' : ''}</StyAdminPartErrorSpan>
					<StyAdminPartInputListSelect id='category_id_item' name='category_id_item' value={item_ci} onChange={ctrler.handleChangeAItem_ci}>
						{
							sectionList.map((sectionI, liId) => {
								liId++;
								return (
									<optgroup label={sectionI.name} key={liId}>
									{
										categoryList.map((categoryI, liId) => {
											liId++;
											if (sectionI.id === categoryI.section_id) {
												return (<option key={liId} value={categoryI.id}>{categoryI.name}</option>);
											} else {
												return ('')
											}
										})
									}
									</optgroup>
								);
							})
						}
					</StyAdminPartInputListSelect>
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel>Disponible :</StyAdminPartInputLabel>
					<StyAdminPartInputRadioLabel htmlFor='available_y_item'>Oui</StyAdminPartInputRadioLabel>
					<StyAdminPartInputRadioInput type='radio' id='available_y_item' name='available_item' value='Y' defaultChecked onClick={ctrler.handleChangeAItem_av} />
					<StyAdminPartInputRadioLabel htmlFor='available_n_item'>Non</StyAdminPartInputRadioLabel>
					<StyAdminPartInputRadioInput type='radio' id='available_n_item' name='available_item' value='N' onClick={ctrler.handleChangeAItem_av} />
				</StyAdminPartInputDiv>

				<StyAdminPartInputDiv>
					<StyAdminPartInputLabel>Activer :</StyAdminPartInputLabel>
					<StyAdminPartInputRadioLabel htmlFor='enable_y_item'>Oui</StyAdminPartInputRadioLabel>
					<StyAdminPartInputRadioInput type='radio' id='enable_y_item' name='enable_item' value='Y' defaultChecked onClick={ctrler.handleChangeAItem_en} />
					<StyAdminPartInputRadioLabel htmlFor='enable_n_item'>Non</StyAdminPartInputRadioLabel>
					<StyAdminPartInputRadioInput type='radio' id='enable_n_item' name='enable_item' value='N' onClick={ctrler.handleChangeAItem_en} />
				</StyAdminPartInputDiv>

				<StyAdminPartErrorDiv>
					{ctrler.errorEpty_all ? 'Veuillez remplir les champs obligatoires' : ''}
				</StyAdminPartErrorDiv>
				<StyAdminPartInputDiv>
					<StyAdminSaveInputButton onClick={onCLickSave}>Sauvegarder</StyAdminSaveInputButton>
				</StyAdminPartInputDiv>
			</form>
		</div>
	);
}

export default AdminAddItem;