
import { useSelector } from 'react-redux';
import { useState } from 'react';

import { Getter } from '../../../../generaltools';

import {
	globalStore,
	getAdminCurrSection,
	getFAdmSec_na,
	getFAdmSec_or,
	getFAdmSec_en,
	getFAdmSec_de,
	getAllSection,
	setFAdmSec_or,
} from '../../../../globalstate';

import { AdminAddManageSection_ctrl } from '../../../../controler/';

import {
	AdminAddSection,
	AdminManageSection,
} from './';

function AdminAddManageSection({addManage}) {

	const section_all = useSelector(getAllSection(globalStore.getState())) || [];
	const maxOrdreP1 = Getter.getMaxSectionOrderP1(section_all);

	const section_old = useSelector(getAdminCurrSection(globalStore.getState())) || {};

	const section_na = useSelector(getFAdmSec_na(globalStore.getState())) || "";
	const section_or = useSelector(getFAdmSec_or(globalStore.getState())) || 0;
	const section_en = useSelector(getFAdmSec_en(globalStore.getState()));
	const section_de = useSelector(getFAdmSec_de(globalStore.getState()));

	if (section_or === 0) {
		globalStore.dispatch(setFAdmSec_or(maxOrdreP1));
	}

	const [errorEpty_all, setErrorEpty_all] = useState(false);
	const [errorEpty_na, setErrorEpty_na] = useState(false);
	const [errorEpty_or, setErrorEpty_or] = useState(false);

	const forCtrler = {
		section_old: section_old,
		section_na: section_na,
		section_or: section_or,
		section_en: section_en,
		section_de: section_de,
		errorEpty_all: errorEpty_all,
		errorEpty_na: errorEpty_na,
		errorEpty_or: errorEpty_or,
		setErrorEpty_all: setErrorEpty_all,
		setErrorEpty_na: setErrorEpty_na,
		setErrorEpty_or: setErrorEpty_or,
		maxOrdreP1: maxOrdreP1,
	};
	const ctrler = AdminAddManageSection_ctrl(forCtrler);

	return (
		<div>
			{ addManage === 'A' ? (
					<AdminAddSection
						onCLickSave={ctrler.handleSaveSectionAdd}
						ctrler={ctrler} />
				) : (
					<AdminManageSection
						onCLickSave={ctrler.handleSaveSectionMod}
						ctrler={ctrler} />
				)
			}
		</div>
	);
} 

export default AdminAddManageSection;


					// <AdminManageSection
					// 	onCLickSave={ctrler.handleSaveSectionMod}
					// 	ctrler={ctrler}
					// 	sectionCurr={section_old} />