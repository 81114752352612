
import styled from 'styled-components';


const StyCarouselPlaceDiv = styled.div`
	width: 90%;
	max-width: 700px;
	height: 90%;
	max-height: 700px;

	margin-top: 10px;
	margin-left: auto;
	margin-right: auto;
`

const StyCarouselItemDiv = styled.div`
`

const StyCarouselImgImg = styled.img`
	width: 90%;
	max-width: 700px;
	height: 90%;
	max-height: 700px;
`

const StyCarouselLegendP = styled.p`
`

export {
	StyCarouselPlaceDiv,
	StyCarouselItemDiv,
	StyCarouselImgImg,
	StyCarouselLegendP,
};