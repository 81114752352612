
import {
	globalStore,
	setAdminCurrSection,
	setAdminIsActive,
	setAdminModeAddMod,

	setFAdmSec_na,
	setFAdmSec_or,
	setFAdmSec_en,
	setFAdmSec_de,
} from '../../../globalstate';


function Section_ctrl(forCtrler) {

	const isCurrentSection = () => {
		if (forCtrler.section.id === forCtrler.currentSection.id) {
			return true;
		} else {
			return false;
		}
	};

	const isAdminCurrentSection = () => {
		if (forCtrler.section.id === forCtrler.adminCurrentSection.id) {
			return true;
		} else {
			return false;
		}
	};

	const onClickAdminSection = () => {
		globalStore.dispatch(setAdminCurrSection(forCtrler.section));
		globalStore.dispatch(setAdminIsActive("SECTION"));
		globalStore.dispatch(setAdminModeAddMod("M"));

		globalStore.dispatch(setFAdmSec_na(forCtrler.section.name));
		globalStore.dispatch(setFAdmSec_or(forCtrler.section.order));
		globalStore.dispatch(setFAdmSec_en(forCtrler.section.enable));
		globalStore.dispatch(setFAdmSec_de(forCtrler.section.is_delete));
	};

	return {
		isCurrentSection: isCurrentSection,
		isAdminCurrentSection: isAdminCurrentSection,
		onClickAdminSection: onClickAdminSection,
	};
} 

export default Section_ctrl;